import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const BackNavigation = ({ title, subtitle }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-5 justify-start">
      <div className="flex gap-x-3 cursor-pointer" onClick={handleBack}>
        <ArrowLeftIcon className="h-6 w-6 " />
        <div className="flex flex-col">
          <span className="text-xl mb-2 font-semibold">{title}</span>
          <span className="text-slate-500">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default BackNavigation;
