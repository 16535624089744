import { UserCircleIcon, AcademicCapIcon, BriefcaseIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";

export const DEFAULT_ROUTES = {
  APP: "/",
};

export const ROUTES = {
  LOGIN: "/login",
  LANDING_PAGE: "/landing",
  EMPLOYEES: "/employees",
  CREATE_EMPLOYEE: "/employee/create",
  VIEW_EMPLOYEE: "/employee/:empId",
  MY_ISSUES: "/myissues",
  VIEW_ISSUES: "/issues/:issueId",
  HR_ISSUES: "/hrissues",
  SENIOR_MANAGEMENT_ISSUES: "/seniorManagementIssues",
  VIEW_EMPLOYEEISSUES: "/employeeissuedetails/:issueId",
  SENIOR_MANAGEMENT: "/seniorManagement",
  SENIOR_MANAGEMENT_EMPLOYEE_VIEW: "/seniorManagementEmployeeView",
  DEPARTMENT_ISSUES: "/departmentissues",
  CONSENT: "/consent",
};


export const myDataVerticalNavigation = [
  { name: "Profile", id: 1, href: "#profile", icon: UserCircleIcon },
  { name: "Education", id: 2, href: "#education", icon: AcademicCapIcon },
  { name: "Employment", id: 3, href: "#employment", icon: BriefcaseIcon },
  { name: "Bank", id: 4, href: "#bank", icon: BuildingLibraryIcon },
]; 