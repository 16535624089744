import { useState, useEffect } from "react";
import { getHelper } from "../utils/axios/apiServices";
import { ERROR_MESSAGES } from "../utils/constants/messages";
import { ISSUES } from "../lib/api";

const useEmployeeIssueData = (issueId) => {
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);
        const data = await getHelper(
          `${ISSUES.ISSUE_DETAILS}/${issueId}`,
          () => {},
          {}
        );
        setEmployeeData(data);
      } catch (err) {
        setError(err.message || ERROR_MESSAGES.FETCH_DATA_ERROR);
      } finally {
        setLoading(false);
      }
    };

    if (issueId) {
      fetchEmployeeData();
    }
  }, [issueId]);

  return { employeeData, loading, error, setEmployeeData };
};

export default useEmployeeIssueData;
