import React, { useEffect, useState } from "react";
import BreadCrumb from "./BreadCrumb";
import { USER } from "../../lib/api";
import {
  API_FIELD_NAMES,
  BREADCRUMB_STATUS,
  FIELD_HEADING,
  FIELD_HEADING_DESCRIPTION,
  INPUT_TYPES,
} from "../../utils/constants/keywords";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import { createFormValidationSchema, editPageInitialValues, editPageInputs } from "./editPageInputs";
import { postFile, postHelper } from "../../utils/axios/apiServices";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/messages";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FormActionButton from "./FormActionButton";
import ProfilePhoto from "./ProfilePhoto";
import SectionHeader from "./SectionHeader";
import EmployeeInputFields from "./EmployeeInputFields";
import EmployeeArrayInputFields from "./EmployeeArrayInputFields";
import EmployeeSkillsInputField from "./EmployeeSkillsInputField";
import { breadCrumbSteps } from "../../utils/constants/enums";

function EmployeeForm() {
  const location = useLocation();
  const [addDetail, setAddDetail] = useState({
    education: [{ id: 1, detail: 0 }],
    certification: [{ id: 2, detail: 0 }],
    previousEmployments: [{ id: 3, detail: 0 }],
    currentEmployment: [{ id: 4, detail: 0 }],
    [API_FIELD_NAMES.MY_TRAINING]: [{ id: 5, detail: 0 }],
    [API_FIELD_NAMES.FAMILY_DETAILS_OBJ]:[{ id: 6, detail: 0 }],
  });
  const navigate = useNavigate();

  // BreadCrumb steps
  const [steps, setSteps] = useState(breadCrumbSteps);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const handleBreadcrumbNext = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex((step) => step.status === BREADCRUMB_STATUS.CURRENT);
      if (currentIndex !== -1 && currentIndex < newSteps.length - 1) {
        newSteps[currentIndex].status = BREADCRUMB_STATUS.COMPLETE;
        newSteps[currentIndex + 1].status = BREADCRUMB_STATUS.CURRENT;
      }
      return newSteps;
    });
  };

  const handleBreadcrumbBack = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex((step) => step.status === BREADCRUMB_STATUS.CURRENT);

      if (currentIndex !== -1 && currentIndex > 0) {
        newSteps[currentIndex - 1].status = BREADCRUMB_STATUS.CURRENT;
        newSteps[currentIndex].status = BREADCRUMB_STATUS.UPCOMMING;
      }
      return newSteps;
    });
  };

  const handleBreadcrumbSelect = (selectedIndex) => {
    setSteps((prevSteps) => {
      const newSteps = prevSteps.map((step, index) => {
        if (index < selectedIndex) {
          return { ...step, status: BREADCRUMB_STATUS.COMPLETE };
        } else if (index === selectedIndex) {
          return { ...step, status: BREADCRUMB_STATUS.CURRENT };
        } else {
          return { ...step, status: BREADCRUMB_STATUS.UPCOMMING };
        }
      });
      return newSteps;
    });
  };

  // Add Button
  const handleAddDetail = type => {
    setAddDetail(prevData => {
      return {
        ...prevData,
        [type]: [
          ...prevData[type],
          {
            id: prevData[type][prevData[type].length - 1].id + 1,
            detail: prevData[type][prevData[type].length - 1].detail + 1,
          },
        ],
      };
    });
  };
  

  //Details Remove Button
  const handleSubDetail = (type, selectionId) => {
    const removedDetailType = addDetail[type].filter((detailType) => {
      return detailType.id !== selectionId;
    });
    setAddDetail((prevData) => ({ ...prevData, [type]: removedDetailType }));
  };

  const formik = useFormik({
    initialValues: editPageInitialValues,
    validationSchema: createFormValidationSchema,
    onSubmit: async (values) => {
      const createURL = `${USER.CREATE_USER}`;
      try {
        const response = await postHelper(createURL, values, SUCCESS_MESSAGES.PROFILE_CREATE);
        if (response) {
          navigate(ROUTES.EMPLOYEES);
        } 
      } catch (error) {
        toast.error(ERROR_MESSAGES.TRY_AGAIN);
      }
    },
  });
  const pages = [
    { id: 0, pageName: editPageInputs.Profile },
    { id: 1, pageName: editPageInputs.Personal },
    {
      id: 2,
      pageName: [
        editPageInputs.Education[API_FIELD_NAMES.SPECIALIZATION_OF_STUDY],
        editPageInputs.Education[API_FIELD_NAMES.CERTIFICATION_DETAILS],
        editPageInputs.Education[API_FIELD_NAMES.SKILL_SET],
      ],
    },
    {
      id: 3,
      pageName: [
        editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].currentEmployment,
        editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].previousEmployments,
      ],
    },
    { id: 4, pageName: editPageInputs[API_FIELD_NAMES.MY_TRAINING] },
    {
      id: 5,
      pageName: [
        ...editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.BANK_DETAILS],
        ...editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.PF_ACCOUNT_DETIALS],
      ],
    },
  ];
  // Checking the input field errors
  const hasPageErrorValidation = (page) =>
    page.pageName.some((field) => {
      if (Array.isArray(field)) {
        return field.some((nestedField) => {
          if (Array.isArray(formik.errors[nestedField.array])) {
            return formik.errors[nestedField.array]?.some((nestedFieldError) => {
              return nestedFieldError;
            });
          } else if (formik.errors[nestedField?.ObjName]) {
            return true;
          }
        });
      }
      return formik.errors[field.name];
    });
  useEffect(() => {
    formik.setValues({ ...formik.values, ...location.state });
  }, []);
  useEffect(() => {
    pages.forEach((page) => {
      const hasErrors = hasPageErrorValidation(page);
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[page.id] = {
          ...newSteps[page.id],
          error: hasErrors,
        };
        return newSteps;
      });
    });
  }, [formik.errors]);
  
  const fileNameExtractor = (filename) => {
    const fileNameIndex = filename?.lastIndexOf("/") + 1;
    const fileName = filename?.slice(fileNameIndex);
    const cleanFileName = fileName?.split("_").slice(1).join("_");  // This will remove the first part (ID) and join the rest back together
    return cleanFileName;
  };

  const handleNestedChange = async (array, index, name, value, type) => {
    let data = value;
    if (type === INPUT_TYPES.FILE) {
      const dataFile = value;
      if (dataFile && dataFile.length > 0) {
        try {
          const response = await postFile(USER.EDUCATION_DOC_UPLOAD, dataFile[0], INPUT_TYPES.FILE);

          data = {
            documentId: response?.document?._id,
            url: response?.document?.url,
            fileName:fileNameExtractor(response?.document?.name),
          };
        } catch (error) {
          toast.error(ERROR_MESSAGES.documentUpload);
        }
      }
    }

    formik.setFieldValue(`${array}[${index}][${name}]`, data);
  };

  const handleUpdateSkill = (skillType, updatedSkills) => {
    formik.setValues({
      ...formik.values,
      skillSet: {
        ...formik.values.skillSet,
        [skillType]: updatedSkills,
      },
    });
  };

  const removePhoto = () => {
    formik.setFieldValue(API_FIELD_NAMES.PROFILE_PHOTO, null);
  };

  return (
    <div className="space-y-4">
      <BreadCrumb steps={steps} isFormSubmit={isFormSubmit} handleBreadcrumbSelect={handleBreadcrumbSelect}/>
      <div className=" w-full relative space-y-12 bg-white rounded-lg px-5 lg:px-20 pt-7 py-24 mb-16">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            {/* Profile */}
            {steps[0].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <SectionHeader heading={FIELD_HEADING.PROFILE} description={FIELD_HEADING_DESCRIPTION.PROFILE} />
                <ProfilePhoto
                  photoData={formik.values?.profilePhoto}
                  handleChange={formik.handleChange}
                  removePhoto={removePhoto}
                />
                <EmployeeInputFields inputs={editPageInputs.Profile} formik={formik} isFormSubmit={isFormSubmit} />
              </>
            )}

            {/* Personal Detail */}
            {steps[1].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeInputFields
                inputs={editPageInputs.Personal}
                formik={formik}
                heading={FIELD_HEADING.PERSONAL_DETAILS}
                description={FIELD_HEADING_DESCRIPTION.PERSONAL_DETAILS}
                isFormSubmit={isFormSubmit}
              />
            )}

            {/* Education Detail */}
            {steps[2].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                {/* Education Section*/}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.EDUCATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.EDUCATION_DETAILS}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.SPECIALIZATION_OF_STUDY]}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.education}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.EDUCATION_OBJ)}
                  handleSubDetail={selectionId => handleSubDetail(API_FIELD_NAMES.EDUCATION_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Certificaition section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.CERTIFICATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.CERTIFICATION_DETAILS}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.CERTIFICATION_DETAILS]}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.certification}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.CERTIFICATION_OBJ)}
                  handleSubDetail={selectionId => handleSubDetail(API_FIELD_NAMES.CERTIFICATION_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Skillset section */}
                <EmployeeSkillsInputField
                  heading={FIELD_HEADING.SKILLSET}
                  description={FIELD_HEADING_DESCRIPTION.SKILLSET}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.SKILL_SET]}
                  value={formik.values.skillSet}
                  handleUpdateSkill={handleUpdateSkill}
                  formik={formik}
                  isFormSubmit={isFormSubmit}
                />
              </>
            )}

            {/* Employment Detail */}
            {steps[3].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                {/* Current Employment section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.EMPLOYMENT_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS}
                  inputs={editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].currentEmployment}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.currentEmployment}
                  hideAdd={true}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Previous Employment section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.EMPLOYMENT_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.PREVIOUS_EMPLOYMENT_DETAILS}
                  inputs={editPageInputs?.[API_FIELD_NAMES.EMPLOYMENT_DETAIL]?.previousEmployments}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.previousEmployments}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ)}
                  handleSubDetail={selectionId => handleSubDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                {/* <hr className="my-12" /> */}
              </>
            )}

            {/* Employee Training */}
            {steps[4].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeArrayInputFields
                heading={FIELD_HEADING.MY_TRAINING}
                description={FIELD_HEADING_DESCRIPTION.MY_TRAINING}
                inputs={editPageInputs[API_FIELD_NAMES.MY_TRAINING]}
                formik={formik}
                handleNestedChange={handleNestedChange}
                addSection={addDetail[API_FIELD_NAMES.MY_TRAINING]}
                handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.MY_TRAINING)}
                handleSubDetail={selectionId => handleSubDetail(API_FIELD_NAMES.MY_TRAINING, selectionId)}
                isFormSubmit={isFormSubmit}
              />
            )}

            {/* Bank Details */}
            {steps[5].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <div className="my-12">
                  {/* Bank Details section */}
                  <EmployeeInputFields
                    inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.BANK_DETAILS]}
                    formik={formik}
                    heading={FIELD_HEADING.BANK_DETAILS}
                    description={FIELD_HEADING_DESCRIPTION.BANK_DETAILS}
                    isFormSubmit={isFormSubmit}
                  />
                </div>
                <hr className="mb-12" />
                {/* ESI Account */}
                <EmployeeInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.ESI_ACCOUNT]}
                  formik={formik}
                  heading={FIELD_HEADING.ESI_ACCOUNT}
                  description={FIELD_HEADING_DESCRIPTION.ESI_ACCOUNT}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Insurance details */}
                <EmployeeInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.INSURANCE_DETAILS]}
                  formik={formik}
                  heading={FIELD_HEADING.INSURANCE_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.INSURANCE_DETAILS}
                  isFormSubmit={isFormSubmit}
                />
                {/* Family details */}
                <div className="mt-6">
                  <EmployeeArrayInputFields
                  heading={FIELD_HEADING.FAMILY_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.FAMILY_DETAILS}
                  inputs={editPageInputs[API_FIELD_NAMES.FAMILY_DETAILS]}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail[API_FIELD_NAMES.FAMILY_DETAILS_OBJ]}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.FAMILY_DETAILS_OBJ)}
                  handleSubDetail={selectionId => handleSubDetail(API_FIELD_NAMES.FAMILY_DETAILS_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                </div>
                <hr className="my-12" />
                {/* PF Details section */}
                <EmployeeInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.PF_ACCOUNT_DETIALS]}
                  formik={formik}
                  heading={FIELD_HEADING.PF_ACCOUNT_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.PF_ACCOUNT_DETAILS}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Labour Welfare Fund section */}
                <EmployeeInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.LABOUR_WELFARE_FUND]}
                  formik={formik}
                  heading={FIELD_HEADING.LABOUR_WELFARE_FUND}
                  description={FIELD_HEADING_DESCRIPTION.LABOUR_WELFARE_FUND}
                  isFormSubmit={isFormSubmit}
                />
              </>
            )}
          </div>
          <FormActionButton
            steps={steps}
            handleBreadcrumbBack={handleBreadcrumbBack}
            handleBreadcrumbNext={handleBreadcrumbNext}
            setIsFormSubmit={setIsFormSubmit}
          />
        </form>
      </div>
    </div>
  );
}

export default React.memo(EmployeeForm);
