import "./App.css";
import LoginForm from "./Components/Loginform";
import ProtectedRoutes from "./Components/ProtectedRoute";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { DEFAULT_ROUTES, ROUTES } from "./utils/constants/routes";
import MyIssues from "./Components/EmployeeIssues/MyIssues";
import MyIssuesDetails from "./Components/EmployeeIssues/MyIssuesDetails";
import HrIssues from "./Components/HrIssues/HrIssues";
import EmployeeIssueDetails from "./Components/HrIssues/EmployeeIssueDetails";
import SeniorManagementIssues from "./Components/SeniorManagementIssues/SeniorManagementIssues";
import AllDepartmentIssues from "./Components/HrIssues/AllDepartmentIssues";

import {
  CreateEmployee,
  EmployeeListing,
  EmployeeProfileManager,
  LandingPage,
  SeniorManagement,
} from "./pages";
import SeniorManagementEmployeeView from "./Components/SeniorManagement/SeniorManagementEmployeeView/SeniorManagementEmployeeView";
import { GlobalProvider } from "./utils/context/Global/GlobalProvider";
import ConsentTableListing from "./Components/Consent/Dashboard/ConsentTableListing";

function App() {
  const router = createBrowserRouter([
    {
      path: ROUTES.LOGIN,
      element: <LoginForm />,
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          path: DEFAULT_ROUTES.APP,
          element: <Navigate to={ROUTES.LOGIN} replace={true} />,
        },
        {
          path: ROUTES.LANDING_PAGE,
          element: <LandingPage />,
        },
        {
          path: ROUTES.EMPLOYEES,
          element: <EmployeeListing />,
        },
        {
          path: ROUTES.CREATE_EMPLOYEE,
          element: <CreateEmployee />,
        },
        {
          path: ROUTES.VIEW_EMPLOYEE,
          element: <EmployeeProfileManager />,
        },
        {
          path: ROUTES.SENIOR_MANAGEMENT,
          element: <SeniorManagement />,
        },
        {
          path: `${ROUTES.SENIOR_MANAGEMENT_EMPLOYEE_VIEW}/:empId`,
          element: <SeniorManagementEmployeeView />,
        },
        {
          path: ROUTES.MY_ISSUES,
          element: <MyIssues />,
        },
        {
          path: ROUTES.VIEW_ISSUES,
          element: <MyIssuesDetails />,
        },

        {
          path: ROUTES.HR_ISSUES,
          element: <HrIssues />,
        },
        {
          path: `${ROUTES.VIEW_EMPLOYEEISSUES}/:issueId`,
          element: <EmployeeIssueDetails />,
        },

        {
          path: ROUTES.SENIOR_MANAGEMENT_ISSUES,
          element: <SeniorManagementIssues />,
        },

        { path: ROUTES.DEPARTMENT_ISSUES, element: <AllDepartmentIssues /> },
        { path: ROUTES.CONSENT, element: <ConsentTableListing /> },
      ],
    },
  ]);

  return (
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  );
}

export default App;
