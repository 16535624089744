import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

const SelectedOptionsView = ({ selectedOptions, removeSelectedOption }) => (
  <div>
    {selectedOptions?.map(option => (
      <span
        key={option}
        className="text-blue-500 inline-flex items-center justify-between text-sm gap-x-1.5 px-3 bg-blue-100 rounded-l-full rounded-r-full mr-3 py-1 my-2 ">
        <span>{option}</span>
        <XMarkIcon onClick={() => removeSelectedOption(option)} className="size-4" />
      </span>
    ))}
  </div>
);

const FilteredOptionsView = ({ filteredOptions }) => (
  <div>
    {filteredOptions.length > 0 && (
      <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {filteredOptions.map(option => (
          <ComboboxOption
            key={option}
            value={option}
            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600  data-[focus]:text-white">
            <span className="block truncate group-data-[selected]:font-semibold">{option}</span>

            <span className="absolute inset-y-0 right-0 hidden items-center pr-4  text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    )}
  </div>
);

export default function ComboBox({
  label,
  Options,
  defaultOptions,
  handleUpdate,
  required,
  setSkillsetList,
}) {
  
  const [query, setQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions || []);
  useEffect(() => {
    handleUpdate(selectedOptions);
  }, [selectedOptions]);

  const filteredOptions =
    query === ''
      ? Options
      : Options.filter(option => {
          return option.toLowerCase().includes(query.toLowerCase());
        }).filter(option => {
          return !selectedOptions.some(existOption => existOption === option);
        });

  const removeSelectedOption = removedOption => {
    const newSelectedOption = selectedOptions.filter(existOption => existOption !== removedOption);
    setSelectedOptions(newSelectedOption);
    setSkillsetList(prevData => [...prevData, removedOption]);
  };
  return (
    <div>
      <Combobox
        as="div"
        value={selectedOptions}
        onChange={option => {
          if (option && !selectedOptions.includes(option)) {
            setQuery('');
            setSelectedOptions(prevData => [...prevData, option]);
          }
        }}>
        <Label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
          {required && <span className="text-red-500"> *</span>}
        </Label>
        <div className="relative mt-2">
          <ComboboxInput
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={event => setQuery(event.target.value)}
            onBlur={() => setQuery('')}
            placeholder="Search for keywords"
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
          <FilteredOptionsView filteredOptions={filteredOptions} />
        </div>
      </Combobox>
      <SelectedOptionsView
        selectedOptions={selectedOptions}
        removeSelectedOption={removeSelectedOption}
      />
    </div>
  );
}
