import React from "react";
import Button from "./Button";

const HeaderContent = ({ title, description,buttonName,buttonLink,buttonType,buttonVariant,onClick }) => (
  <div className="mb-7 mt-3  w-full flex flex-col sm:flex-row justify-between gap-y-6 sm:items-center px-5 md:px-20 ">
    <div className="flex flex-col gap-y-2">
      <span className="text-2xl font-semibold">{title}</span>
      <span className="text-slate-900 font-normal text-4">{description}</span>
    </div>
    <Button children={buttonName} href={buttonLink} type={buttonType} variant={buttonVariant} onClick = {onClick} />
  </div>
);
export default React.memo(HeaderContent);
