import React from "react";
import Icon from "./Icon";

const EmployeeHeader = ({ empName, empDesignation, empPhoto }) => {
  return (
    <div className="relative flex items-center space-x-3     py-5">
      <div className="h-16 w-16 rounded-full bg-imgBg shadow-sm border flex justify-center items-center">
        <div>
          <Icon empName={empName} empPhoto={empPhoto} />
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-xl font-semibold text-gray-900">{empName}</p>
          <p className="truncate text-base font-medium text-projectDesc text-wrap">{empDesignation}</p>
        </div>
      </div>
    </div>
  );
};
export default React.memo(EmployeeHeader);
