import React from "react";
import { ACTION_BUTTONS, INPUT_TYPES } from "../../utils/constants/keywords";
import { ERROR_MESSAGES } from "../../utils/constants/messages";

const CustomInputFile = ({ id, handleChange, value, testid, multiple, acceptFileFormat }) => {
  return (
    <form>
      <div class="flex flex-row items-center mt-2 rounded-md border-0 shadow-sm ring-1 ring-inset focus:outline-none ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600">
        <input
          type={INPUT_TYPES.FILE}
          id={id}
          onChange={handleChange}
          hidden
          multiple={multiple}
          accept={acceptFileFormat}
          data-testid={testid}
        />
        <label for={id} class="block text-sm text-slate-500 mr-4 py-2 px-4 border-r font-normal cursor-pointer">
          {ACTION_BUTTONS.CHOOSE_FILE}
        </label>
        <label class="text-sm text-slate-500">{value || ERROR_MESSAGES.NO_FILE_CHOOSEN}</label>
      </div>
    </form>
  );
};

export default React.memo(CustomInputFile);
