import * as React from "react";
import PropTypes from "prop-types";
import useUserRole from "../../hooks/useUserRole";
import { CARD_ICON } from "../../utils/Components/imageUrls";
function StatCard({ icon, count, label }) {
  return (
    <section className="flex mx-5 my-5 gap-4 py-5 pr-28 pl-5 bg-white rounded max-md:pr-5">
      <div className="flex justify-center items-center self-start px-2 w-10 h-10 bg-blue-700 rounded">
        <img loading="lazy" src={icon} alt="" className="w-6 aspect-square" />
      </div>
      <div className="flex flex-col">
        <h2 className="justify-center text-xl font-semibold text-black whitespace-nowrap">
          {count}
        </h2>
        <p className="text-xs font-medium text-gray-500">{label}</p>
      </div>
    </section>
  );
}

function IssueCards({
  openIssues,
  totalIssues,
  highPriorityIssues,
  inProgressIssues,
  resolvedIssues,
}) {
  const userRole = useUserRole(); // Get the user role from the hook

  const cardData = [
    {
      icon: CARD_ICON,
      count: openIssues,
      label: "Open Issues",
      alt: "card icon",
    },
    {
      icon: CARD_ICON,
      count: totalIssues,
      label: "Total Issues",
      alt: "card icon",
    },
    {
      icon: CARD_ICON,
      count: highPriorityIssues,
      label: "High Priority",
      alt: "card icon",
    },
    {
      icon: CARD_ICON,
      count: inProgressIssues,
      label: "In Progress",
      alt: "card icon",
    },
    {
      icon: CARD_ICON,
      count: resolvedIssues,
      label: "Resolved",
      alt: "card icon",
    },
  ];

  const restrictedRoles = [
    "HR",
    "Sales",
    "Admin",
    "ITSupport",
    "Marketing",
    "TAG",
    "DEG",
    "Finance",
  ];

  const filteredCardData = restrictedRoles.includes(userRole)
    ? cardData.slice(1)
    : cardData;

  return (
    <main className="flex justify-between rounded-lg max-md:flex-wrap">
      {filteredCardData.map((card) => (
        <StatCard key={card.label} {...card} />
      ))}
    </main>
  );
}

IssueCards.propTypes = {
  openIssues: PropTypes.number.isRequired,
  totalIssues: PropTypes.number.isRequired,
  highPriorityIssues: PropTypes.number.isRequired,
  inProgressIssues: PropTypes.number.isRequired,
  resolvedIssues: PropTypes.number.isRequired,
};

export default IssueCards;
