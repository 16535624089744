import React from "react";
import { dateFormat, monthFormat } from "../../utils/dateConverter";
import { API_FIELD_NAMES, FIELD_LABELS, NOT_AVAILABLE } from "../../utils/constants/keywords";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";

const DocumentField = ({ data }) => (
  <div>
    {data?.length > 0 ? (
      <div className="inline-flex gap-x-2">
        {FIELD_LABELS.DOCUMENTS}{" "}
        <ArrowDownTrayIcon
          data-testid="download_document"
          className="h-5 w-5 text-blue-700 cursor-pointer"
          onClick={() => fileDownload(data)}
        />
      </div>
    ) : (
      NOT_AVAILABLE
    )}
  </div>
);

const fileDownload = url => {
  window.open(url, "_self");
};

const FormattedValue = ({ value }) => {
  const identifyDateFormat = dateString => {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return iso8601Pattern.test(dateString);
  };

  const identifyMonthFormat = dateString => {
    const monthFormat = /^\d{4}-(0[1-9]|1[0-2])$/;
    return monthFormat.test(dateString);
  };

  if (identifyDateFormat(value)) {
    return <>{dateFormat(value)}</>;
  } else if (identifyMonthFormat(value)) {
    return <>{monthFormat(value)}</>;
  }

  return <>{(value === true && "Yes" || value) || NOT_AVAILABLE}</>;
};

const EmployeeViewField = ({ field, employee }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 py-10">
        {field?.map(items => {
          return (
            <div key={items?.label} className=" flex flex-col gap-y-3 px-1">
              <span className="text-sm font-medium leading-6 text-gray-900">{items?.label}</span>
              <span className=" text-slate-500 font-normal truncate sm:whitespace-normal">
                {items.name === API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS ||
                items.name === API_FIELD_NAMES.PAYSLIP_DOCUMENTS ||
                items.name === API_FIELD_NAMES.OFFER_LETTER_DOCUMENTS ||
                items.name === API_FIELD_NAMES.EXPERIENCE_LETTER_DOCUMENTS ||
                items.name === API_FIELD_NAMES.CERTIFICATION_DOCUMENTS ? (
                  <DocumentField data={employee?.[items.name]?.url} />
                ) : (
                  <FormattedValue
                    value={
                      (employee?.[items.name]?.length > 0 && (employee?.[items.name]).toString()) ||
                      (employee?.[items.name]?.length == 0 && NOT_AVAILABLE) ||
                      employee?.[items.name]
                    }
                  />
                )}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(EmployeeViewField);
