import React, { useState } from "react";
import { ACTION_BUTTONS, FIELD_HEADING_DESCRIPTION, MODAL_TYPE } from "../../utils/constants/keywords";
import Button from "../ReusableComponents/Button";
import Modal from "../ReusableComponents/Modal";

const ViewHistory = () => {
  return (
    <div>
      <div className={" leading-6  text-gray-900   text-lg text-left font-semibold pb-2"}>Employee History</div>
      <table >
        <div className="min-w-full bg-white border rounded-lg">
        <thead className="bg-gray-50  text-gray-500 uppercase text-xs font-semibold rounded-t-lg">
          <tr>
            <th className="p-4 text-left">BILLABILITY</th>
            <th className="p-4 text-left">CLIENT</th>
            <th className="p-4 text-left">START DATE</th>
            <th className="p-4 text-left">END DATE</th>
          </tr>
        </thead>
        <tbody className="rounded-b-lg">
          <tr>
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
          </tr>
          <tr className="bg-stone-50">
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
            <td className="p-4 ">hekk</td>
          </tr>
        </tbody>
        </div>
      </table>
    </div>
  );
};

const SectionHeader = ({ sectionObj, hideEdit, addButton, handleAddDetail }) => {
  const [openModal, setopenModal] = useState(false);
  return (
    <div className=" flex justify-between items-center text-black pt-10">
      <div className="flex  gap-x-1">
        <h2 className="text-lg font-bold leading-7 text-gray-900">{sectionObj?.heading?.label}</h2>
        <p className=" text-lg font-normal leading-6 text-gray-900">{sectionObj?.heading?.field}</p>
      </div>
      {addButton && (
        <Button
          testId={`${sectionObj?.heading?.label}-add_button`}
          children={ACTION_BUTTONS.ADD}
          onClick={() => handleAddDetail(sectionObj?.heading?.label)}
          variant={ACTION_BUTTONS.ADD}
        />
      )}
      {hideEdit || (
        <div className="hidden  group-hover:block">
          {false && sectionObj?.heading?.field === FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS && (
            <Button children={ACTION_BUTTONS.VIEW_HISTORY} onClick={() => setopenModal(true)} type="button" variant={"save"} />
          )}
        </div>
      )}
      <Modal open={openModal} setOpen={setopenModal} type={MODAL_TYPE.MANUAL} manualMsg={<ViewHistory />} />
    </div>
  );
};

export default React.memo(SectionHeader);
