import React from "react";
import EmployeeViewField from "./EmployeeViewField";
import { viewPageInputs } from "./viewPageInputs";
import SectionHeader from "./SectionHeader";
import EmployeeArrayInputFields from "./EmployeeArrayInputFields";
import EmployeeInputFields from "./EmployeeInputFields";
import ProfilePhoto from "../CreateEmployee/ProfilePhoto";
import Button from "../ReusableComponents/Button";
import { PencilIcon } from "@heroicons/react/24/outline";

const EditableUserDetailSection = ({
  sectionTitle,
  handleAddDetail,
  formik,
  handleNestedChange,
  handleFormCancel,
  inputs,
  editSelection,
  handleEdit,
  hideEdit,
  hideAdd = true,
  isPhotoEdit,
  setProfilePhotoId,
  handleSubDetail,
  disable,
  removePhoto
}) => {
  const isArraySection = Array.isArray(formik.values?.[sectionTitle]);
  return (
    <div className=" group px-5  sm:px-20">
      { (
        <SectionHeader
          sectionObj={viewPageInputs[sectionTitle]}
          sectionName={sectionTitle}
          hideEdit={hideEdit || editSelection[viewPageInputs[sectionTitle].id]?.isVisible === true}
          addButton={
            hideAdd &&
            isArraySection &&
            editSelection[viewPageInputs[sectionTitle].id]?.isVisible === true &&
            formik.values?.[sectionTitle]?.length < 4
          }
          handleAddDetail={handleAddDetail}
          handleEdit={handleEdit}
        />
      )}
      <div className="relative">
        {(editSelection[viewPageInputs[sectionTitle].id].isVisible === false &&
          ((isArraySection &&
            (formik.values?.[sectionTitle]?.length ? formik.values[sectionTitle] : [{}]).map(items => (
              <EmployeeViewField key={items?._id} field={viewPageInputs[sectionTitle].fields} employee={items} />
            ))) || (
            <EmployeeViewField
              key={viewPageInputs[sectionTitle].id}
              field={viewPageInputs[sectionTitle].fields}
              employee={(viewPageInputs[sectionTitle]?.objectName && formik.values?.[viewPageInputs[sectionTitle].objectName]) || formik.values}
            />
          ))) || (
          <>
            {(isArraySection && (
              <EmployeeArrayInputFields
                formik={formik}
                inputs={inputs}
                handleNestedChange={handleNestedChange}
                sectionTitle={sectionTitle}
                handleFormCancel={handleFormCancel}
                handleSubDetail={handleSubDetail}
              />
            )) || (
              <>
                {isPhotoEdit && (
                  <ProfilePhoto photoData={formik.values?.profilePhoto} handleChange={formik.handleChange} setProfilePhotoId={setProfilePhotoId} removePhoto={removePhoto}/>
                )}
                <EmployeeInputFields handleFormCancel={handleFormCancel} formik={formik} inputs={inputs} disable={disable} />
              </>
            )}
          </>
        )}
          {(hideEdit || editSelection[viewPageInputs[sectionTitle].id]?.isVisible) ||<div className="absolute top-10 right-0 hidden  group-hover:block">
         <Button
            testId={`${viewPageInputs[sectionTitle]?.heading?.label}-edit_button`}
            children={<PencilIcon className="h-4 w-4" />}
            onClick={() => handleEdit(viewPageInputs[sectionTitle]?.id)}
            variant={"edit"}
          />
        </div>}
      </div>
    </div>
  );
};

export default EditableUserDetailSection;
