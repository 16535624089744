import { CheckIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { BREADCRUMB_STATUS } from '../../utils/constants/keywords';
import { XMarkIcon } from '@heroicons/react/20/solid';

const BreadCrumbSteps = ({ step, isFormSubmit }) => {
  return (
    <div className="group flex w-full items-center">
      <span className="flex items-center px-6 py-7 text-sm font-medium">
        {(isFormSubmit && step.error && (
          <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-red-600 group-hover:bg-red-800">
            <XMarkIcon className="h-3 w-3 text-white" aria-hidden="true" data-testid="xmark-icon" />
          </span>
        )) ||
          (step.status === BREADCRUMB_STATUS.COMPLETE && (
            <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
              <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
            </span>
          )) ||
          (step.status === BREADCRUMB_STATUS.CURRENT && (
            <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
              <span className="text-indigo-600 text-xs">{step.id}</span>
            </span>
          )) || (
            <span className="text-gray-500 group-hover:text-gray-900 text-xs border-2 rounded-full h-5 w-5 text-center">
              {step.id}
            </span>
          )}
      </span>
      <span className="ml-4 text-base font-medium text-gray-900">{step.name}</span>
    </div>
  );
};

function BreadCrumb({ steps, isFormSubmit,handleBreadcrumbSelect }) {
  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 xl:flex xl:divide-y-0 bg-white">
        {steps.map((step, stepIdx) => (
          <li onClick={()=>handleBreadcrumbSelect(stepIdx)} key={step.name} className="relative xl:flex xl:flex-1 cursor-pointer">
            <BreadCrumbSteps isFormSubmit={isFormSubmit} step={step} />
            {stepIdx !== steps.length - 1 && (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 xl:block"
                  aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none">
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default React.memo(BreadCrumb);
