import { useReducer, useCallback, useEffect, useMemo } from "react";
import GlobalReducer from "./GlobalReducer.js";
import { GLOBAL_REDUCER_ACTIONS } from "../../constants/actionTypes.js";
import { GlobalContext, globalInitialState } from "./GlobalContext.js";
import LocalStorage from "../../StorsgeUtil/LocalStorage.js";
import { getHelper } from "../../axios/apiServices.js";
import { ISSUES, USER } from "../../../lib/api.js";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../constants/messages.js";

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, globalInitialState);

  const setEmployees = useCallback((employees) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_EMPLOYEES,
      payload: employees,
    });
  }, []);

  const setIssues = useCallback((issues) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_ISSUES,
      payload: issues,
    });
  }, []);

  const setTotalIssues = useCallback((totalIssues) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_TOTAL_ISSUES,
      payload: totalIssues,
    });
  }, []);

  const setHRIssues = useCallback((hrissues) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_HR_ISSUES,
      payload: hrissues,
    });
  }, []);

  const setFilteredEmployees = useCallback((filteredEmployees) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_FILTERED_EMPLOYEES,
      payload: filteredEmployees,
    });
  }, []);

  const setSearchQuery = useCallback((searchQuery) => {
    dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_SEARCH_QUERY,
      payload: searchQuery,
    });
  }, []);

  const setGlobalSearchActive = useCallback((isActive)=>{
  dispatch({
      type: GLOBAL_REDUCER_ACTIONS.SET_GLOBAL_SEARCH_ACTIVE,
      payload: isActive,
    });
  }, []);

  const fetchIssues = useCallback(
    (page = 1, status = "") => {
      const userData = JSON.parse(LocalStorage.getUserData());
      const loggedInUserEmployeeID = userData?.employeeId;

      if (loggedInUserEmployeeID) {
        const apiUrl = `${ISSUES.GET_ISSUE_BY_EMPLOYEE_ID}${loggedInUserEmployeeID}?page=${page}&status=${status}`;

        getHelper(apiUrl)
          .then((response) => {
            if (response.success && response.data) {
              setIssues(response.data);
              setTotalIssues(response.pagination?.totalIssues || 0);
            } else {
              console.error(
                "Debug - Unexpected API response structure:",
                response
              );
              setIssues([]);
              setTotalIssues(0);
            }
          })
          .catch(() => {
            toast.error(ERROR_MESSAGES.FETCH_ISSUES_ERROR);
            setIssues([]);
            setTotalIssues(0);
          });
      } else {
        toast.error(ERROR_MESSAGES.NO_EMPLOYEE_ID_FOUND);
      }
    },
    [setIssues, setTotalIssues]
  );
  const setEnums = useCallback(enums=>{
    dispatch({
      type:GLOBAL_REDUCER_ACTIONS.SET_ENUMS,
      payload:enums
    })
  },[])
  useEffect(() => {
    const userData = JSON.parse(LocalStorage.getUserData());
    getHelper(USER.GET_ENUMS,setEnums)
    if (userData?.employeeId) {
      getHelper(USER.GET_ALL_USERS, setEmployees);
      fetchIssues(1, "");
      if (userData.role === "HR") {
        getHelper(ISSUES.GET_HR_ISSUES, setHRIssues);
      }
    } else {
      // Handle case where user is not logged in
      toast.error(ERROR_MESSAGES.NOT_AUTHENTICATED);
      // Potentially redirect to login page or show login prompt
    }
  }, [fetchIssues, setEmployees, setHRIssues]);
 

  const contextData = useMemo(
    () => ({
      employees: state.employees,
      issues: state.issues,
      totalIssues: state.totalIssues,
      hrissues: state.hrissues,
      filteredEmployees: state.filteredEmployees,
      searchQuery: state.searchQuery,
      setFilteredEmployees,
      setSearchQuery,
      setEmployees,
      setIssues,
      setHRIssues,
      enums:state.enums,
      fetchIssues,
      isActiveGlobalSearch :state.isActiveGlobalSearch,
      setGlobalSearchActive
    }),
    [
      state.employees,
      state.issues,
      state.totalIssues,
      state.hrissues,
      state.filteredEmployees,
      state.searchQuery,
      fetchIssues,
      state.isActiveGlobalSearch
    ]
  );

  return (
    <GlobalContext.Provider value={contextData}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
