import React from "react";

 function VerticalNavigation({navigation}) {
  return (
    <span className="fixed inline-flex right-3.5 md:right-11">
      <ul role="list" className="">
        {navigation.map(item => (
          <li key={item.name}>
            <a
              href={item.href}
              className={`bg-white text-gray-700 hover:bg-gray-50 hover:text-indigo-600 group flex gap-x-3 px-3 py-5 md:px-6 md:py-9 text-sm font-semibold leading-6 ${
                item.id === 1 && "rounded-t-full border-t-2"
              } ${item.id === 4 && "rounded-b-full border-b-2"} border-x-2 border-black/60`}>
              <item.icon aria-hidden="true" className={"text-black/60 group-hover:text-indigo-600 h-5 w-5 shrink-0"} />
            </a>
          </li>
        ))}
      </ul>
    </span>
  );
}

export default React.memo(VerticalNavigation)