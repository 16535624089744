import {
  API_FIELD_NAMES,
  FIELD_HEADING,
  FIELD_HEADING_DESCRIPTION,
  FIELD_LABELS,
} from '../../utils/constants/keywords';

export const viewPageInputs = {
  Profile: {
    id: 0,
    heading: {
      label: FIELD_HEADING.PROFILE,
      field: FIELD_HEADING_DESCRIPTION.PROFILE,
    },
    fields: [
      { label: FIELD_LABELS.EMPLOYEE_ID, name: API_FIELD_NAMES.EMPLOYEE_ID },
      { label: FIELD_LABELS.DEPARTMENT, name: API_FIELD_NAMES.DEPARTMENT },
      {
        label: FIELD_LABELS.DATE_OF_BIRTH,
        name: API_FIELD_NAMES.DATE_OF_BIRTH,
      },
      {
        label: FIELD_LABELS.CONTACT_NUMBER,
        name: API_FIELD_NAMES.CONTACT_NUMBER,
      },  
      {
        label: FIELD_LABELS.EMAIL_ADDRESS,
        name: API_FIELD_NAMES.EMAIL_ADDRESS,
      },
    ],
  },
  Personal: {
    id: 1,
    heading: {
      label: FIELD_HEADING.PERSONAL_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.PERSONAL_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.FATHERS_NAME, name: API_FIELD_NAMES.FATHERS_NAME },
      { label: FIELD_LABELS.MOTHERS_NAME, name: API_FIELD_NAMES.MOTHERS_NAME },
      {
        label: FIELD_LABELS.MARITAL_STATUS,
        name: API_FIELD_NAMES.MARITAL_STATUS,
      },
      { label: FIELD_LABELS.GENDER, name: API_FIELD_NAMES.GENDER },
      { label: FIELD_LABELS.SPOUSE, name: API_FIELD_NAMES.SPOUSE },
      {
        label: FIELD_LABELS.NO_OF_CHILDREN,
        name: API_FIELD_NAMES.NO_OF_CHILDREN,
      },
      { label: FIELD_LABELS.BLOOD_GROUP, name: API_FIELD_NAMES.BLOOD_GROUP },
      { label: FIELD_LABELS.GENDER, name: API_FIELD_NAMES.GENDER },
      {
        label: FIELD_LABELS.EMERGENCY_CONTACT_NAME,
        name: API_FIELD_NAMES.EMERGENCY_CONTACT_NAME,
      },
      {
        label: FIELD_LABELS.EMERGENCY_CONTACT_NUMBER,
        name: API_FIELD_NAMES.EMERGENCY_CONTACT_NUMBER,
      },
      { label: FIELD_LABELS.NATIONALITY, name: API_FIELD_NAMES.NATIONALITY },
      { label: FIELD_LABELS.AADHAR_NO, name: API_FIELD_NAMES.AADHAR_NO },
      { label: FIELD_LABELS.PAN_CARD_NO, name: API_FIELD_NAMES.PAN_CARD_NO },
      { label: FIELD_LABELS.PASSPORT_NO, name: API_FIELD_NAMES.PASSPORT_NO },
      {
        label: FIELD_LABELS.CURRENT_ADDRESS,
        name: API_FIELD_NAMES.CURRENT_ADDRESS,
      },
      {
        label: FIELD_LABELS.PERMANENT_ADDRESS,
        name: API_FIELD_NAMES.PERMANENT_ADDRESS,
      },
    ],
  },
  education: {
    id: 2,
    heading: {
      label: FIELD_HEADING.EDUCATION_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.EDUCATION_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.EDUCATION, name: API_FIELD_NAMES.EDUCATION },
      {
        label: FIELD_LABELS.UNIVERSITY_BOARD,
        name: API_FIELD_NAMES.UNIVERSITY_BOARD,
      },
      {
        label: FIELD_LABELS.FIELD_OF_STUDY,
        name: API_FIELD_NAMES.FIELD_OF_STUDY,
      },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
      {
        label: FIELD_LABELS.GRADES_PERCENTAGE,
        name: API_FIELD_NAMES.GRADES_PERCENTAGE,
      },
       {
        label: FIELD_LABELS.UPLOADED_DOCUMENTS,
        name: API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS,
      },
      
    ],
  },
  certification: {
    id: 3,
    heading: {
      label: FIELD_HEADING.CERTIFICATION_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.CERTIFICATION_DETAILS,
    },
    fields: [
      {
        label: FIELD_LABELS.CERTIFICATION_NAME,
        name: API_FIELD_NAMES.CERTIFICATION_NAME,
      },
      { label: FIELD_LABELS.INSTITUTE, name: API_FIELD_NAMES.INSTITUTE },
      {
        label: FIELD_LABELS.CERTIFICATION_YEAR,
        name: API_FIELD_NAMES.CERTIFICATION_YEAR,
      },
      {
        label: FIELD_LABELS.UPLOADED_DOCUMENTS,
        name: API_FIELD_NAMES.CERTIFICATION_DOCUMENTS,
      },
    ],
  },
  [API_FIELD_NAMES.SKILL_SET]: {
    id: 4,
    heading: {
      label: FIELD_HEADING.SKILLSET,
      field: FIELD_HEADING_DESCRIPTION.SKILLSET,
    },
    fields: [
      { label: FIELD_LABELS.EXPERT_SKILL, name: API_FIELD_NAMES.EXPERT_SKILL },
      {
        label: FIELD_LABELS.INTERMEDIATE_SKILL,
        name: API_FIELD_NAMES.INTERMEDIATE_SKILL,
      },
      { label: FIELD_LABELS.BASIC_SKILL, name: API_FIELD_NAMES.BASIC_SKILL },
    ],
  },
  currentEmployment: {
    id: 5,
    heading: {
      label: FIELD_HEADING.EMPLOYMENT_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.DESIGNATION, name: API_FIELD_NAMES.DESIGNATION },
      {
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
      },
      { label: FIELD_LABELS.CLIENT, name: API_FIELD_NAMES.CLIENT },
      { label: FIELD_LABELS.TAGGING, name: API_FIELD_NAMES.TAGGING },
      { label: FIELD_LABELS.STATUS, name: API_FIELD_NAMES.STATUS },
      { label: FIELD_LABELS.SUPERVISOR, name: API_FIELD_NAMES.SUPERVISOR },
      { label: FIELD_LABELS.MY_PROJECT, name: API_FIELD_NAMES.MY_PROJECT },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
      { label: FIELD_LABELS.STREAM, name: API_FIELD_NAMES.STREAM },
      { label: FIELD_LABELS.BAND_LEVEL, name: API_FIELD_NAMES.BAND_LEVEL },
      { label: FIELD_LABELS.LOCATION, name: API_FIELD_NAMES.LOCATION },
      {
        label: FIELD_LABELS.CURRENT_CTC_IN_LPA,
        name: API_FIELD_NAMES.CURRENT_CTC_IN_LPA,
      },
      {
        label: FIELD_LABELS.PREVIOUS_CTC_IN_LPA,
        name: API_FIELD_NAMES.PREVIOUS_CTC_IN_LPA,
      },
      {
        label: FIELD_LABELS.TOTAL_YEAR_EXPERIENCE,
        name: API_FIELD_NAMES.TOTAL_YEAR_EXPERIENCE,
      },
      {
        label: FIELD_LABELS.TOTAL_RELEVANT_EXPERIENCE,
        name: API_FIELD_NAMES.TOTAL_RELEVANT_EXPERIENCE,
      },
    ],
  },
  previousEmployments: {
    id: 6,
    heading: {
      label: FIELD_HEADING.EMPLOYMENT_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.PREVIOUS_EMPLOYMENT_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.DESIGNATION, name: API_FIELD_NAMES.DESIGNATION },
      { label: FIELD_LABELS.COMPANY_NAME, name: API_FIELD_NAMES.COMPANY_NAME },
      {
        label: FIELD_LABELS.COMPANY_LOCATION,
        name: API_FIELD_NAMES.COMPANY_LOCATION,
      },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
      { label: FIELD_LABELS.CTC_IN_LPA, name: API_FIELD_NAMES.CTC_IN_LPA },
      {
        label: FIELD_LABELS.OFFER_LETTER_UPLOAD,
        name: API_FIELD_NAMES.OFFER_LETTER_DOCUMENTS,
      },
      {
        label: FIELD_LABELS.RELIEVING_LETTER_UPLOAD,
        name: API_FIELD_NAMES.EXPERIENCE_LETTER_DOCUMENTS,
      },
      {
        label: FIELD_LABELS.PAY_SLIP_UPLOAD,
        name: API_FIELD_NAMES.PAYSLIP_DOCUMENTS,
      },
    ],
  },
  [API_FIELD_NAMES.MY_TRAINING]: {
    id: 7,
    heading: {
      label: FIELD_HEADING.MY_TRAINING,
      field: FIELD_HEADING_DESCRIPTION.MY_TRAINING,
    },
    fields: [
      {
        label: FIELD_LABELS.TRAINING_NAME,
        name: API_FIELD_NAMES.TRAINING_NAME,
      },
      {
        label: FIELD_LABELS.TRAINING_STATUS,
        name: API_FIELD_NAMES.TRAINING_STATUS,
      },
      { label: FIELD_LABELS.DATE_OF_COMPLETION, name: API_FIELD_NAMES.DATE_OF_COMPLETION },
    ],
  },
  [API_FIELD_NAMES.BANK_DETAILS]: {
    id: 8,
    heading: {
      label: FIELD_HEADING.BANK_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.BANK_DETAILS,
    },
    fields: [
      { label: FIELD_LABELS.NAME_AS_PER_BANK, name: API_FIELD_NAMES.NAME_AS_PER_BANK },
      { label: FIELD_LABELS.BANK_NAME, name: API_FIELD_NAMES.BANK_NAME },
      {
        label: FIELD_LABELS.BANK_ACCOUNT_NO,
        name: API_FIELD_NAMES.BANK_ACCOUNT_NO,
      },
      { label: FIELD_LABELS.BRANCH, name: API_FIELD_NAMES.BRANCH },
      { label: FIELD_LABELS.IFSC, name: API_FIELD_NAMES.IFSC },
    ],
  },
  [API_FIELD_NAMES.ESI_ACCOUNT]: {
    id: 9,
    heading: {
      label: FIELD_HEADING.ESI_ACCOUNT,
      field: FIELD_HEADING_DESCRIPTION.ESI_ACCOUNT,
    },
    fields: [
      { label: FIELD_LABELS.ESI_NUMBER, name: API_FIELD_NAMES.ESI_NUMBER },
    ],
  },
  [API_FIELD_NAMES.INSURANCE_DETAILS]: {
    id: 10,
    heading: {
      label: FIELD_HEADING.INSURANCE_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.INSURANCE_DETAILS,
    },
    objectName : API_FIELD_NAMES.INSURANCE_OBJ,
    fields: [
      { label: FIELD_LABELS.POLICY_NUMBER, name: API_FIELD_NAMES.POLICY_NUMBER },
      { label: FIELD_LABELS.START_DATE, name: API_FIELD_NAMES.START_DATE },
      { label: FIELD_LABELS.END_DATE, name: API_FIELD_NAMES.END_DATE },
      { label: FIELD_LABELS.NAME, name: API_FIELD_NAMES.NAME },
      { label: FIELD_LABELS.GENDER, name: API_FIELD_NAMES.GENDER },
      { label: FIELD_LABELS.DATE_OF_BIRTH, name: API_FIELD_NAMES.DATE_OF_BIRTH },
      { label: FIELD_LABELS.CARD_NUMBER, name: API_FIELD_NAMES.CARD_NUMBER },
    ],
  },
  [API_FIELD_NAMES.FAMILY_DETAILS_OBJ]: {
    id: 11,
    heading: {
      label: FIELD_HEADING.FAMILY_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.FAMILY_DETAILS,
    },
    objectName : API_FIELD_NAMES.INSURANCE_OBJ,
    fields: [
      { label: FIELD_LABELS.RELATION, name: API_FIELD_NAMES.RELATION },
      { label: FIELD_LABELS.NAME, name: API_FIELD_NAMES.NAME },
      { label: FIELD_LABELS.GENDER, name: API_FIELD_NAMES.GENDER },
      { label: FIELD_LABELS.DATE_OF_BIRTH, name: API_FIELD_NAMES.DATE_OF_BIRTH },
      { label: FIELD_LABELS.CARD_NUMBER, name: API_FIELD_NAMES.CARD_NUMBER },
    ],
  },
  [API_FIELD_NAMES.PF_ACCOUNT_DETIALS]: {
    id: 12,
    heading: {
      label: FIELD_HEADING.PF_ACCOUNT_DETAILS,
      field: FIELD_HEADING_DESCRIPTION.PF_ACCOUNT_DETAILS,
    },
    objectName : API_FIELD_NAMES.STATUTORY,
    fields: [
      { label: FIELD_LABELS.PF_NUMBER, name: API_FIELD_NAMES.PF_NUMBER },
      { label: FIELD_LABELS.UAN, name: API_FIELD_NAMES.UAN },
      { label: FIELD_LABELS.PF_DOJ, name: API_FIELD_NAMES.PF_DOJ },
      { label: FIELD_LABELS.EXIST_EPF_MEMBER, name: API_FIELD_NAMES.EXIST_EPF_MEMBER },
      { label: FIELD_LABELS.EXIST_EPF_CONTRIBUTION, name: API_FIELD_NAMES.EXIST_EPF_CONTRIBUTION },
      { label: FIELD_LABELS.EXIST_EPS_CONTRIBUTION, name: API_FIELD_NAMES.EXIST_EPS_CONTRIBUTION },
      { label: FIELD_LABELS.DOCUMENT_TYPE, name: API_FIELD_NAMES.DOCUMENT_TYPE },
    ],
  },
  [API_FIELD_NAMES.LABOUR_WELFARE_FUND]: {
    id: 13,
    heading: {
      label: FIELD_HEADING.LABOUR_WELFARE_FUND,
      field: FIELD_HEADING_DESCRIPTION.LABOUR_WELFARE_FUND,
    },
    fields: [
      { label: FIELD_LABELS.UNDER_LWF, name: API_FIELD_NAMES.UNDER_LWF },
    ],
  },
};
