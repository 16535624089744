"use client";

import React, { useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { ACTION_BUTTONS, MODAL_TYPE } from "../../utils/constants/keywords";
import TextInputField from "./TextInputField";
import { CheckIcon } from "@heroicons/react/24/outline";

const TypeSelection = ({ type, data, state, handleChange, msg }) => {
  if (type === MODAL_TYPE.INPUT) {
    return (
      <p className="text-sm text-gray-500">
        {data?.map((item) => (
          <div key={item.id} className="mt-4 ">
            <TextInputField value={state[item.name]} input={item} handleChange={handleChange} />
          </div>
        ))}
      </p>
    );
  } else if (type === MODAL_TYPE.SUCCESS_RES) {
    return (
      <p className="text-sm text-gray-500">
        <div className="mt-4 font-medium text-base text-gray-400">{msg}</div>
      </p>
    );
  }
};

const Modal = ({ open, setOpen, title, fieldInput, onSubmit, type, msg, manualMsg }) => {
  const [formValues, setFormValues] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = () => {
    (type === MODAL_TYPE.INPUT && onSubmit(formValues)) || setOpen(false);
  };
  useEffect(() => {
    setFormValues({});
  }, [open]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {type === MODAL_TYPE.MANUAL && (
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-8 sm:pt-5 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              {manualMsg}
            </DialogPanel>
          ) || (
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-8 sm:pt-5 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              {type !== MODAL_TYPE.INPUT && (
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ring-2 ring-green-600 mb-1">
                  <CheckIcon aria-hidden="true" className="h-9 w-9 font-black text-green-600" />
                </div>
              )}
              <div>
                <div className=" text-center ">
                  <DialogTitle
                    as="h3"
                    className={`  leading-6  text-gray-900  ${
                      (type === MODAL_TYPE.INPUT && "text-lg text-left font-semibold") ||
                      "text-2xl text-center font-bold"
                    }`}
                  >
                    {title}
                  </DialogTitle>
                  <div className="mt-2">
                    <TypeSelection
                      data={fieldInput}
                      handleChange={handleInputChange}
                      state={formValues}
                      type={type}
                      msg={msg}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:gap-3 text-center">
                <button
                  onClick={handleSubmit}
                  className={`inline-flex  items-center justify-center rounded-lg bg-blue-600 px-3 py-2.5 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                    (type === MODAL_TYPE.INPUT && "w-full") || "px-16"
                  }`}
                >
                  {(type === MODAL_TYPE.INPUT && ACTION_BUTTONS.SEND) || ACTION_BUTTONS.OK}
                </button>
              </div>
            </DialogPanel>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default React.memo(Modal);
