import React from 'react';
import { STATS_LABELS } from '../../utils/constants/keywords';

function EmployeeListingStats({ taggingCounts }) {
  const stats = [
    {
      id: 1,
      name: STATS_LABELS.BILLABLE,
      stat: taggingCounts?.Billable || 0,
    },
    {
      id: 2,
      name: STATS_LABELS.NON_BILLABLE,
      stat: taggingCounts?.NAD || 0,
    },
    {
      id: 3,
      name: STATS_LABELS.BENCH,
      stat: taggingCounts?.Bench || 0,
    },
    {
      id: 4,
      name: STATS_LABELS.INVESTMENT,
      stat: taggingCounts?.Investment || 0,
    },
  ];

  return (
    <div className="px-5 md:px-20  w-full ">
      <div className=" grid grid-cols-1 gap-y-6 md:grid-cols-2 sm:gap-x-6 sm:gap-y-6 pb-8  lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8 lg:gap-y-8 ">
        {stats.map(item => (
          <div
            key={item.id}
            className="relative overflow-hidden flex items-baseline gap-x-3 rounded  bg-white px-4 py-3  shadow ">
            <p className="truncate text-2xl font-semibold text-gray-900 ">{item.stat}</p>
            <p className="text-base font-medium text-gray-500">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(EmployeeListingStats);
