export const roleTypes = [
  { id: 2, name: "HR" },
  { id: 3, name: "Finance" },
  { id: 4, name: "TAG" },
  { id: 5, name: "Sales" },
  { id: 6, name: "Marketing" },
  { id: 7, name: "IT Development" },
  { id: 8, name: "IT Testing" },
  { id: 9, name: "Creative" },
  { id: 10, name: "Business Development" },
  { id: 11, name: "Operations" },
  { id: 12, name: "Senior Management" },
  { id: 13, name: "COE" },
];

export const maritalStatusType = [
  { id: 2, name: "Single" },
  { id: 3, name: "Married" },
  { id: 4, name: "Divorced" },
  { id: 5, name: "Widowed" },
];

export const gender = [
  { id: 2, name: "Male" },
  { id: 3, name: "Female" },
  { id: 4, name: "Other" },
];

export const bloodGroups = [
  { id: 2, name: "O+" },
  { id: 3, name: "O-" },
  { id: 4, name: "A+" },
  { id: 5, name: "A-" },
  { id: 6, name: "B+" },
  { id: 7, name: "B-" },
  { id: 8, name: "AB+" },
  { id: 9, name: "AB-" },
];

export const educationLevel = [
  { id: 2, name: "PhD" },
  { id: 3, name: "Masters" },
  { id: 4, name: "Bachelors" },
  { id: 5, name: "Higher Secondary" },
  { id: 6, name: "Diploma" },
  { id: 7, name: "Secondary" },
];

export const employmentType = [
  { id: 2, name: "FullTime" },
  { id: 3, name: "PartTime" },
  { id: 4, name: "Temporary" },
  { id: 5, name: "Contract" },
  { id: 6, name: "Consultant" },
  { id: 7, name: "Internship" },
];

export const taggingType = [
  { id: 2, name: "Investment" },
  { id: 3, name: "Bench" },
  { id: 4, name: "NAD" },
  { id: 5, name: "Billable" },
  { id: 6, name: "Business Support" },
  { id: 7, name: "Management" },
  { id: 8, name: "DNB" },
];

export const statusType = [
  { id: 2, name: "Active" },
  { id: 3, name: "Resigned" },
  { id: 4, name: "Buffer" },
  { id: 5, name: "InActive" },
  { id: 6, name: "Absconded" },
  { id: 7, name: "NewJoinee" },
  { id: 8, name: "Terminated" },
];

export const myProjectType = [
  { id: 2, name: "Wellzy" },
  { id: 3, name: "TestServ" },
  { id: 4, name: "Wiztap" },
  { id: 5, name: "Internal app's" },
  { id: 6, name: "Client project" },
];

export const clientType = [
  { id: 2, name: "Amagi" },
  { id: 3, name: "Amazon" },
  { id: 4, name: "Philips" },
  { id: 5, name: "Sony" },
  { id: 6, name: "Fossil" },
  { id: 7, name: "ANZ" },
  { id: 8, name: "R2V" },
  { id: 9, name: "Ellow.IO" },
  { id: 10, name: "Sartorius" },
  { id: 11, name: "IDC Tech (Wipro)" },
  { id: 12, name: "Diageo" },
  { id: 13, name: "Logituit" },
  { id: 14, name: "Channel Works" },
  { id: 15, name: "Smart Genie" },
  { id: 16, name: "Nerd Rabbit" },
  { id: 17, name: "Fitbod" },
  { id: 18, name: "Mediassist" },
  { id: 19, name: "Mediacorp" },
];


export const bandLevelType = [
  { id: 2, name: "A1" },
  { id: 3, name: "A2" },
  { id: 4, name: "A3" },
  { id: 5, name: "A4" },
  { id: 6, name: "A5" },
  { id: 7, name: "A6" },
  { id: 8, name: "B1" },
  { id: 9, name: "B2" },
  { id: 10, name: "B3" },
  { id: 11, name: "B4" },
  { id: 12, name: "C1" },
];

export const completionStatusType = [
  { id: 2, name: "InComplete" },
  { id: 3, name: "InProgress" },
  { id: 4, name: "Completed" },
];

export const streamType = [
  { id: 2, name: "Delivery career stream" },
  { id: 3, name: "Sales & Marketing career stream" },
  { id: 4, name: "Business Acceleration career stream" },
];

export const breadCrumbSteps = [
  { id: "1", name: "Profile", status: "current", error: false },
  { id: "2", name: "Personal", status: "upcoming", error: false },
  { id: "3", name: "Education", status: "upcoming", error: false },
  { id: "4", name: "Employment", status: "upcoming", error: false },
  { id: "5", name: "Training", status: "upcoming", error: false },
  { id: "6", name: "Bank Details", status: "upcoming", error: false },
];

export const skillsetTechnologies = [
  "React",
  "Angular",
  "Vue.js",
  "JavaScript",
  "TypeScript",
  "HTML5",
  "CSS3",
  "Sass",
  "Less",
  "Tailwind CSS",
  "Bootstrap",
  "jQuery",
  "Backbone.js",
  "Ember.js",
  "Svelte",
  "Gatsby",
  "Next.js",
  "Nuxt.js",
  "Redux",
  "MobX",
  "Webpack",
  "Parcel",
  "Gulp",
  "Grunt",
  "Node.js",
  "Express.js",
  "Django",
  "Flask",
  "Ruby on Rails",
  "ASP.NET",
  "Spring Boot",
  "Laravel",
  "PHP",
  "Python",
  "Java",
  "C#",
  "C++",
  "Ruby",
  "Go",
  "Rust",
  "Kotlin",
  "Scala",
  "Elixir",
  "Haskell",
  "Perl",
  "Swift",
  "Objective-C",
  "MySQL",
  "PostgreSQL",
  "SQLite",
  "MongoDB",
  "Cassandra",
  "Redis",
  "Elasticsearch",
  "Oracle",
  "SQL Server",
  "Firebase",
  "CouchDB",
  "MariaDB",
  "Amazon DynamoDB",
  "Amazon Aurora",
  "Google Cloud Spanner",
  "InfluxDB",
  "Neo4j",
  "Docker",
  "Kubernetes",
  "Jenkins",
  "Travis CI",
  "CircleCI",
  "GitLab CI",
  "Terraform",
  "Ansible",
  "Puppet",
  "Chef",
  "AWS",
  "Azure",
  "Google Cloud Platform",
  "Heroku",
  "Netlify",
  "Vercel",
  "Git",
  "SVN",
  "Mercurial",
  "Bitbucket",
  "GitHub",
  "GitHub Actions",
  "AWS Lambda",
  "Serverless Framework",
  "OpenShift",
  "Istio",
  "Jest",
  "Mocha",
  "Chai",
  "Jasmine",
  "Cypress",
  "Selenium",
  "Puppeteer",
  "Playwright",
  "TestCafe",
  "QUnit",
  "Enzyme",
  "AVA",
  "Karma",
  "Protractor",
  "RSpec",
  "JUnit",
  "TestNG",
  "NUnit",
  "Robot Framework",
  "Cucumber",
  "SpecFlow",
  "BrowserStack",
  "Sauce Labs",
  "Appium",
  "Detox",
  "Espresso",
  "XCUITest",
  "Tox",
  "React Native",
  "Flutter",
  "Java (Android)",
  "Xamarin",
  "Ionic",
  "PhoneGap",
  "Cordova",
  "NativeScript",
  "GraphQL",
  "REST",
  "gRPC",
  "Apollo",
  "Express Gateway",
  "Kong",
  "NGINX",
  "Zuul",
  "API Gateway",
  "OAuth",
  "JWT",
  "Passport.js",
  "OpenAPI (Swagger)",
  "Postman",
  "WordPress",
  "Drupal",
  "Joomla!",
  "Magento",
  "Shopify",
  "WooCommerce",
  "Ghost",
  "Contentful",
  "Sanity",
  "Strapi",
  "Prismic",
  "BigCommerce",
  "PrestaShop",
  "Google Analytics",
  "Mixpanel",
  "Segment",
  "Amplitude",
  "New Relic",
  "Datadog",
  "Prometheus",
  "Grafana",
  "Splunk",
  "Elastic APM",
  "Sentry",
  "Logstash",
  "Kibana",
  "Nagios",
  "Zabbix",
  "Raygun",
  "CloudWatch",
  "Dynatrace",
  "GitLab",
  "Trello",
  "Jira",
  "Confluence",
  "Slack",
  "Microsoft Teams",
  "Asana",
  "Basecamp",
  "Monday.com",
  "npm",
  "Yarn",
  "pnpm",
  "Bower",
  "Maven",
  "Gradle",
  "Ant",
  "Make",
  "Bazel",
  "Buck",
  "CMake",
  "OWASP",
  "Metasploit",
  "Burp Suite",
  "Nmap",
  "Wireshark",
  "Nessus",
  "Snort",
  "Suricata",
  "Netcat",
  "Aircrack-ng",
  "OpenSSL",
  "HashiCorp Vault",
  "TensorFlow",
  "PyTorch",
  "Keras",
  "Scikit-learn",
  "Pandas",
  "NumPy",
  "SciPy",
  "Matplotlib",
  "NLTK",
  "SpaCy",
  "OpenCV",
  "Hugging Face",
  "GPT",
  "AutoML",
  "MLFlow",
  "Tesseract",
  "Unity",
  "Unreal Engine",
  "Godot",
  "Cocos2d",
  "GameMaker Studio",
  "Pygame",
  "Phaser",
  "CryEngine",
  "Construct",
  "Electron",
  "Tauri",
  "WebAssembly",
  "Blazor",
  "ASP.NET Core",
  "SignalR",
  "WebRTC",
  "Three.js",
  "Babylon.js",
  "D3.js",
  "TensorFlow.js",
  "WebGL",
  "NextAuth.js",
  "Mithril.js",
  "Preact",
  "Alpine.js",
  "Phoenix",
  "Silverlight",
  "Flash",
  "SQL",
  "NoSQL",
  "T-SQL",
  "PL/SQL",
  "CQL",
  "Gremlin",
  "Cypher",
  "HiveQL",
  "SoapUI",
  "Insomnia",
  "Karate DSL",
  "Rest-Assured",
  "Pact",
  "API Fortress",
  "Runscope",
  "Fiddler",
  "Apigee",
  "Hadoop",
  "Spark",
  "Flink",
  "Kafka",
  "Hive",
  "Pig",
  "HBase",
  "Presto",
  "Snowflake",
  "Redshift",
  "BigQuery",
  "Tableau",
  "Power BI",
  "Apache Drill",
  "ElasticSearch",
  "Storm",
  "Kudu",
  "TestComplete",
  "Ranorex",
  "QTP/UFT",
  "Silk Test",
  "Sahi",
  "Eggplant",
  "Katalon Studio",
  "TestProject",
  "Tricentis Tosca",
  "AutoIt",
  "Vagrant",
  "VirtualBox",
  "VMware",
  "Hyper-V",
  "OpenVZ",
  "LXC",
  "Rancher",
  "Nomad",
  "Bamboo",
  "TeamCity",
  "Azure DevOps",
  "Spinnaker",
  "Argo CD",
  "Octopus Deploy",
  "CodeShip",
  "Drone",
  "Buddy",
  "Semaphore",
  "IBM Cloud",
  "Oracle Cloud",
  "Alibaba Cloud",
  "DigitalOcean",
  "Linode",
  "Cloudflare",
  "Mulesoft",
  "Tyk",
  "WSO2 API Manager",
  "3scale",
  "Blockchain",
  "Ethereum",
  "Solidity",
  "Hyperledger",
  "IPFS",
  "Polkadot",
  "IoT",
  "Edge Computing",
  "Quantum Computing",
  "5G",
  "Edge AI",
  "Robotics",
  "VBScript",
  "ColdFusion",
  "PowerBuilder",
  "Delphi",
  "FoxPro",
  "COBOL",
  "Fortran",
  "Lisp",
  "Ada",
  "Pascal",
  "Modula-2",
  "Smalltalk",
  "HyperTalk",
  "HTTP/HTTPS",
  "TCP/IP",
  "UDP",
  "DNS",
  "FTP",
  "SFTP",
  "SSH",
  "Telnet",
  "LDAP",
  "SNMP",
  "SMTP",
  "IMAP",
  "POP3",
  "AMQP",
  "XMPP",
  "RTP",
  "SIP",
  "IPSec",
  "HTTP/2",
  "HTTP/3",
  "Shell Scripting",
  "Bash",
  "PowerShell",
  "Cron",
  "Zsh",
  "Fish",
  "Lua",
  "R",
  "Matlab",
  "Arduino",
  "Raspberry Pi",
  "TensorFlow Lite",
  "Tizen",
  "Wear OS",
  "watchOS",
  "tvOS",

  // UI/UX
  "Figma",
  "Sketch",
  "Adobe XD",
  "CorelDRAW",
  "InVision",
  "Wireframing",
  "Prototyping",
  "User Research",
  "Usability Testing",
  "Responsive Design",
  "Interaction Design",
  "Information Architecture",
  "Visual Design",
  "User Flows",
  "Personas",
  "A/B Testing",
  "Design Thinking",
  "UI Patterns",
  "Typography",
  "Color Theory",
  "Mobile Design",
  "Accessibility Design",
  "Adobe Photoshop",
  "Adobe Illustrator",
  "Affinity Designer",
  "Blender (3D Design)",
  "Axure RP",

  // financeDepartmentSkills
  "Financial Analysis",
  "Budgeting & Forecasting",
  "Accounting",
  "Financial Reporting",
  "Tax Compliance",
  "Cost Management",
  "Internal Auditing",
  "Risk Management",
  "Cash Flow Management",
  "Investment Analysis",
  "Corporate Finance",
  "Financial Modeling",
  "Treasury Management",
  "Mergers & Acquisitions",
  "Financial Planning & Analysis (FP&A)",
  "Credit Management",
  "Asset Management",
  "Revenue Recognition",
  "Payroll Processing",
  "Accounts Payable & Receivable",
  "Regulatory Compliance",
  "Financial Strategy",
  "Capital Budgeting",

  // hrDepartmentSkills
  "Talent Acquisition",
  "Recruitment",
  "Performance Management",
  "Employee Relations",
  "Compensation & Benefits",
  "Learning & Development",
  "HR Compliance",
  "Organizational Development",
  "Succession Planning",
  "Employee Engagement",
  "Payroll Management",
  "HR Analytics",
  "Workforce Planning",
  "Diversity & Inclusion",
  "Labor Law Compliance",
  "Strategic HR Management",
  "Change Management",
  "Conflict Resolution",
  "Employee Onboarding",
  "Training & Development",
  "Employer Branding",
  "HR Technology Management",
  "Workplace Safety",
  "Employee Retention Strategies",
  "HR Policy Development",
];
