import React from "react";
import SectionHeader from "./SectionHeader";
import TextInputField from "../ReusableComponents/TextInputField";
import {  INPUT_TYPES } from "../../utils/constants/keywords";

const EmployeeInputFields = ({ inputs, formik, heading, description, hideHeader = false, isFormSubmit }) => {
  // Updating the values in the nestedObjects
  const handleObjectChange = (event, objectName, name) => {
    const { checked, value, type } = event.target;

    const newValue = type === INPUT_TYPES.CHECKBOX ? checked : value;

    formik.setValues({
      ...formik.values,
      [objectName]: {
        ...formik.values[objectName],
        [name]: newValue,
      },
    });
  };
  return (
    <>
      {!hideHeader && <SectionHeader description={description} heading={heading} />}
      <div className={`mt-10 ${inputs.length !== 1 && "grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"}`}>
        {inputs.map(input => (
          <>
            <TextInputField
              key={input.id}
              input={input}
              value={input.objectName ? formik.values?.[input.objectName]?.[input.name] : formik.values[input.name]}
              handleChange={input.objectName ? event => handleObjectChange(event, input.objectName, input.name) : formik.handleChange}
              touched={formik.touched[input.name]}
              errors={input.objectName ? formik.errors?.[input.objectName]?.[input.name] : formik.errors[input.name]}
              isFormSubmit={isFormSubmit}
              isDisable={
                (input.disableValue &&
                  input.name !== input.disableValue.field &&
                  (input.objectName
                    ? !formik.values[input.disableValue.obj]?.[input.disableValue.field]
                    : !formik.values[input.disableValue.field])) ||
                false
              }
            />
          </>
        ))}
      </div>
    </>
  );
};
export default React.memo(EmployeeInputFields);
