import React from 'react';
import { ACTION_BUTTONS, RESET_PASSWORD_PAGE_LABELS } from '../utils/constants/keywords';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
const ResetPassword = ({
  currentPassword,
  setCurrentPassword,
  handleChange,
  passwordErrorMsg,
  newPassword,
  setNewPassword,
  newPasswordErrorMsg,
  confirmPassword,
  setConfirmPassword,
  confirmPasswordErrorMsg,
  handleResetPassword,
  handleBackToLanding,
  togglePasswordVisibility,
  showPassword,
}) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-left text-3xl font-semibold leading-9 tracking-tight text-gray-900">
          {RESET_PASSWORD_PAGE_LABELS.TITLE}
        </h2>
        <div className="text-base text-passwordDesc my-6">
          {RESET_PASSWORD_PAGE_LABELS.DESCRIPTION}
        </div>
      </div>

      <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" method="PUT">
          <div>
            <label
              htmlFor="currentPassword"
              className="block text-sm font-medium leading-6 text-gray-900">
              {RESET_PASSWORD_PAGE_LABELS.CURRENT_PASSWORD}
            </label>
            <div className="mt-2">
              <input
                id="currentPassword"
                name="currentPassword"
                type="text"
                required
                value={currentPassword}
                onChange={e => handleChange(e, setCurrentPassword)}
                className="username block w-full rounded-md  !border-2 py-4 px-6 text-gray-900  placeholder:text-gray-400 focus:ring-2  sm:text-base sm:leading-6"
              />
              {passwordErrorMsg && <p className="text-red-500 text-sm mt-2">{passwordErrorMsg}</p>}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium leading-6 text-gray-900">
                {RESET_PASSWORD_PAGE_LABELS.NEW_PASSWORD}
              </label>
            </div>
            <div className="mt-2">
              <div className="flex items-center relative">
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Enter your New Password"
                  required
                  value={newPassword}
                  onChange={e => handleChange(e, setNewPassword)}
                  className="  block w-full rounded-md  !border-2 py-4 px-6 text-gray-900  placeholder:text-gray-400 sm:text-base sm:leading-6"
                />
              </div>

              {newPasswordErrorMsg && (
                <p className="text-red-500 text-sm mt-2">{newPasswordErrorMsg}</p>
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-gray-900">
                {RESET_PASSWORD_PAGE_LABELS.CONFIRM_PASSWORD}
              </label>
            </div>
            <div className="mt-2">
              <div className="flex items-center relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={(showPassword && 'text') || 'password'}
                  autoComplete="current-password"
                  placeholder="Enter your New Password"
                  required
                  value={confirmPassword}
                  onChange={e => handleChange(e, setConfirmPassword)}
                  className="  block w-full rounded-md  !border-2 py-4 px-6 text-gray-900  placeholder:text-gray-400 sm:text-base sm:leading-6"
                />
                <span
                  className="absolute right-3 cursor-pointer"
                  onClick={togglePasswordVisibility}>
                  {(showPassword && <EyeIcon className=" h-6 w-6 text-gray-300" />) || (
                    <EyeSlashIcon className=" h-6 w-6 text-gray-300 " />
                  )}
                </span>
              </div>

              {confirmPasswordErrorMsg && (
                <p className="text-red-500 text-sm mt-2">{confirmPasswordErrorMsg}</p>
              )}
            </div>
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              className="flex-1 mr-2 justify-center rounded-md bg-logo px-3 py-3 text-base font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleResetPassword}>
              {ACTION_BUTTONS.SUBMIT}
            </button>
            <button
              type="button"
              className="flex-1 ml-2 justify-center rounded-md bg-gray-300 px-3 py-3 text-base font-normal leading-6 text-gray-700 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
              onClick={handleBackToLanding}>
              {ACTION_BUTTONS.CANCEL}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
