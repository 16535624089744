import React from 'react';
import EmployeeForm from '../Components/CreateEmployee/EmployeeForm';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../utils/constants/routes';
import { ACTION_BUTTONS } from '../utils/constants/keywords';

const CreateEmployee = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(ROUTES.EMPLOYEES);
  };
  return (
    <div>
      <div className="px-5 sm:px-20 flex gap-x-3  mt-6 mb-6 cursor-pointer" onClick={handleBack}>
        <ArrowLeftIcon className="h-6 w-6 " />
        <div className="font-medium text-base ">{ACTION_BUTTONS.BACK}</div>
      </div>
      <div className="px-5 sm:px-20   mb-24">
        <EmployeeForm />
      </div>
    </div>
  );
};

export default CreateEmployee;
