import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ACTION_BUTTONS, LOGIN_PAGE_LABELS } from '../utils/constants/keywords';

const ForgotPassword = ({
  handleBackToLogin,
  handleChange,
  handleForgetPassword,
  setUsername,
  username,
  emailErrorMsg,
  isValidEmail,
}) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center  py-12 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className=" text-left text-3xl font-semibold   tracking-tight text-gray-900">
          {LOGIN_PAGE_LABELS.FORGOT_PASSWORD_TITLE}
        </h2>
        <div className="text-passwordDesc text-base font-normal my-6">
          {LOGIN_PAGE_LABELS.FORGOT_PASSWORD_DESCRIPTION}
        </div>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
              {LOGIN_PAGE_LABELS.MAIL}
            </label>
            <div className="mt-2">
              <div className="flex items-center relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={username}
                  className="email block w-full rounded-md  !border-2 py-4 px-6 text-gray-900   placeholder:text-gray-400 sm:text-base sm:leading-6"
                  onChange={e => handleChange(e, setUsername)}
                />
                <span className="absolute right-3">
                  <EnvelopeIcon className=" h-5 w-5 text-gray-300" />
                </span>
              </div>
              {emailErrorMsg && !isValidEmail(username) && (
                <p className="text-red-500 text-sm mt-2">{emailErrorMsg}</p>
              )}
            </div>
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              className="flex-1 mr-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleForgetPassword}>
              {ACTION_BUTTONS.SUBMIT}
            </button>
            <button
              type="button"
              className="flex-1 ml-2 justify-center rounded-md bg-gray-300 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
              onClick={handleBackToLogin}>
              {ACTION_BUTTONS.CANCEL}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
