import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ROUTES } from '../utils/constants/routes';
import LocalStorage from '../utils/StorsgeUtil/LocalStorage';
import {
  LOGIN_PAGE_LABELS,
  PATH_KEYWORDS,
  PROJECT_TITLE,
  RESET_PASSWORD_PAGE_LABELS,
  roles,
} from '../utils/constants/keywords';
import { getHelper } from '../utils/axios/apiServices';
import { USER } from '../lib/api';
import GlobalSearchBar from './ReusableComponents/GlobalSearchBar/GlobalSearchBar';
import Icon from './ReusableComponents/Icon';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header() {
  const [employee, setEmployee] = useState([]);
  const navigate = useNavigate();
  const { name, employeeId, role } = JSON.parse(LocalStorage.getUserData());
  const location = useLocation();

  useEffect(() => {
    const getURL = `${USER.GET_BY_ID}/${employeeId}`;
    getHelper(getURL, setEmployee);
  }, [employeeId]);

  const handleLogout = () => {
    LocalStorage.logout();
    navigate(ROUTES.LOGIN);
  };
  const handleResetPassword = () => {
    navigate(ROUTES.LOGIN, { state: { resetPassword: true } });
  };

  return (
    <section className="flex justify-between px-20  h-16 max-h-16  items-center w-full bg-white  max-md:px-5 max-md:max-w-full">
      <div className="flex items-center gap-x-5">
        <div
          className="font-bold text-lg text-logo cursor-pointer"
          onClick={() => {
            navigate(ROUTES.LANDING_PAGE);
          }}>
          {PROJECT_TITLE}
        </div>
      </div>
      {location.pathname.includes(PATH_KEYWORDS.EMPLOYEE) && role === roles.HR && (
        <GlobalSearchBar />
      )}
      {location.pathname !== ROUTES.LANDING_PAGE &&
        !location.pathname.includes(PATH_KEYWORDS.EMPLOYEE) && <GlobalSearchBar />}
      <div className="flex gap-4">
        <Menu as="div" className="relative ">
          <div>
            <MenuButton className="relative ">
              <span className="absolute -inset-1.5" />
              <Icon empName={name} empPhoto={employee?.profilePhoto} height="h-10" width="w-10" />
            </MenuButton>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem>
                {({ focus }) => (
                  <div
                    className={classNames(
                      (focus && 'bg-gray-100') || '',
                      'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                    )}
                    onClick={handleResetPassword}>
                    {RESET_PASSWORD_PAGE_LABELS.TITLE}
                  </div>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <div
                    className={classNames(
                      (focus && 'bg-gray-100') || '',
                      'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                    )}
                    onClick={handleLogout}>
                    {LOGIN_PAGE_LABELS.LOGOUT}
                  </div>
                )}
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </section>
  );
}

export default Header;
