import { FunnelIcon } from '@heroicons/react/24/outline';
import React from 'react';
import {
  ACTION_BUTTONS,
  EMPLOYEE_STATUS,
  SENIOR_MANAGEMENT_PAGE_LABELS,
} from '../../../utils/constants/keywords';
import CheckBox from '../../ReusableComponents/CheckBox';
import { SENIOR_MANAGEMENT_ACTIONS } from '../../../utils/constants/actionTypes';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/constants/routes';
import Icon from '../../ReusableComponents/Icon';

const EmployeeDetailsTable = ({
  currentPageEmployeeList,
  FilterTypeList,
  dispatchSeniorManagement,
  seniorManagementState,
}) => {
  const navigate = useNavigate();
  const handleSelectionFilter = (selectionData, filterType) => {
    dispatchSeniorManagement({ type: filterType, payload: selectionData });
  };

  return (
    <section className=" mt-4  w-full max-md:max-w-full ">
      <div className="overflow-x-auto overflow-y-scroll bg-white rounded-lg shadow-sm">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50  text-gray-500 uppercase text-xs font-semibold">
            <tr>
              <th className="p-4 text-left">{SENIOR_MANAGEMENT_PAGE_LABELS.EMPLOYEE_NAME}</th>
              <th className="p-4 text-left">{SENIOR_MANAGEMENT_PAGE_LABELS.SUPERVISOR}</th>
              <th className="p-4 text-left cursor-pointer group" data-testid="department-filter">
                {SENIOR_MANAGEMENT_PAGE_LABELS.DEPARTMENT}{' '}
                {seniorManagementState.departmentFilterList.length > 0 &&
                  `(${seniorManagementState.departmentFilterList.length})`}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom  " />
                <CheckBox
                  filterList={FilterTypeList.department}
                  handleSelectionFilter={selectionData =>
                    handleSelectionFilter(
                      selectionData,
                      SENIOR_MANAGEMENT_ACTIONS.DEPARTMENT_FILTER_LIST
                    )
                  }
                  selectedFilters={seniorManagementState.departmentFilterList}
                />
              </th>
              <th className="p-4 text-left cursor-pointer group"  data-testid="cost_center-filter">
                {SENIOR_MANAGEMENT_PAGE_LABELS.COST_CENTER}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom  " />{' '}
                {seniorManagementState.clientFilterList.length > 0 &&
                  `(${seniorManagementState.clientFilterList.length})`}
                <CheckBox
                  filterList={FilterTypeList.costCenter}
                  handleSelectionFilter={selectionData =>
                    handleSelectionFilter(
                      selectionData,
                      SENIOR_MANAGEMENT_ACTIONS.CLIENT_FILTER_LIST
                    )
                  }
                  selectedFilters={seniorManagementState.clientFilterList}
                />
              </th>

              <th className="p-4 text-left cursor-pointer group"  data-testid="tagging-filter">
                {SENIOR_MANAGEMENT_PAGE_LABELS.TAGGING}
                <FunnelIcon className="w-3.5 inline-flex ml-1 align-bottom  " />{' '}
                {seniorManagementState.taggingFilterList.length > 0 &&
                  `(${seniorManagementState.taggingFilterList.length})`}
                <CheckBox
                  filterList={FilterTypeList.tagging}
                  handleSelectionFilter={selectionData =>
                    handleSelectionFilter(
                      selectionData,
                      SENIOR_MANAGEMENT_ACTIONS.TAGGING_FILTER_LIST
                    )
                  }
                  selectedFilters={seniorManagementState.taggingFilterList}
                />
              </th>
              <th className="p-4 text-left cursor-pointer group" data-testid="status-filter">
                {SENIOR_MANAGEMENT_PAGE_LABELS.STATUS}
                <FunnelIcon className="relative w-3.5 inline-flex ml-1 align-bottom " />{' '}
                {seniorManagementState.statusFilterList.length > 0 &&
                  `(${seniorManagementState.statusFilterList.length})`}
                <CheckBox
                  filterList={FilterTypeList.status}
                  handleSelectionFilter={selectionData =>
                    handleSelectionFilter(
                      selectionData,
                      SENIOR_MANAGEMENT_ACTIONS.STATUS_FILTER_LIST
                    )
                  }
                  selectedFilters={seniorManagementState.statusFilterList}
                />
              </th>

              <th className="p-4 text-left">{SENIOR_MANAGEMENT_PAGE_LABELS.ACTIONS}</th>
            </tr>
          </thead>
          <tbody className="text-gray-500">
            {currentPageEmployeeList?.map((employee, index) => (
              <tr key={employee?._id} className={index % 2 === 0 ? 'bg-white' : 'bg-stone-50'}>
                <td className="p-4 flex gap-4 items-center  ">
                    <Icon empName={employee?.name} empPhoto={employee?.profilePhoto?.url} height='h-12' width='w-12'/>
                  <div>
                    <div className="font-medium text-gray-900 truncate lg:w-60">{employee.name}</div>
                    <div className="text-gray-500">ID: {employee.employeeId}</div>
                  </div>
                </td>
                <td className="p-4">{employee?.currentEmployment?.[0]?.supervisor}</td>
                <td className="p-4">{employee?.role}</td>
                <td className="p-4">{employee?.currentEmployment?.[0]?.client}</td>
                <td className="p-4">{employee?.currentEmployment?.[0]?.tagging}</td>
                <td className="p-4">
                  {(employee?.isActive && EMPLOYEE_STATUS.ACTIVE) || EMPLOYEE_STATUS.INACTIVE}
                </td>
                <td
                  className="p-4 text-blue-700 font-medium cursor-pointer"
                  onClick={() =>
                    navigate(`${ROUTES.SENIOR_MANAGEMENT_EMPLOYEE_VIEW}/${employee.employeeId}`)
                  }>
                  {ACTION_BUTTONS.VIEW}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default React.memo(EmployeeDetailsTable);
