import React, { useContext } from "react";
import useUserRole from "../../hooks/useUserRole";
import { GlobalContext } from "../../utils/context/Global/GlobalContext";
import IssueManager from "./IssueManager";

const HrIssues = () => {
  const userRole = useUserRole();
  const { setHRIssues,isActiveGlobalSearch } = useContext(GlobalContext);
  

  return <IssueManager userRole={userRole} onIssueDataChange={setHRIssues} isActiveGlobalSearch={isActiveGlobalSearch}/>;
};

export default HrIssues;
