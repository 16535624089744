// constants
export const PRIORITY_OPTIONS = ["Low", "Medium", "High"];
export const DEPARTMENT_OPTIONS = [
  "Admin",
  "DEG",
  "Finance",
  "HR",
  "IT Support",
  "Marketing",
  "Sales",
  "TAG",
];

export const REASSIGN_OPTIONS = [
  "Admin",
  "DEG",
  "Finance",
  "HR",
  "IT Support",
  "Marketing",
  "Sales",
  "TAG",
];

export const COSTCENTER_OPTIONS = [
  "Sony",
  "Amazon",
  "Wiztap",
  "Wellzy",
  "Testserv",
  "Client vibe",
  "Mahindra",
  "Internal",
];

export const STATUS_OPTIONS = [
  "Open",
  "Pending",
  "InProgress",
  "Decline",
  "Closed",
  "Completed",
  "Reopened",
  "Withdrawn",
];

export const PRIORITY_CLASSES = {
  High: "bg-rose-100 text-red-800",
  Medium: "bg-violet-100 text-violet-800",
  Low: "bg-yellow-100 text-yellow-800",
};

export const DURATION_OPTIONS = [
  "24 hours",
  "1-3 days",
  "4-7 days",
  "Above 7 days",
];
