import images from "../assets/images";
import {
  LANDING_PAGE_TITLE,
  LANDING_PAGE_TITLE_DESCRIPTION,
  roles,
} from "../utils/constants/keywords";
import { ROUTES } from "../utils/constants/routes";
import LandingCard from "../Components/LandingPage/LandingCard";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";

export default function LandingPage() {
  let userData;
  try {
    userData = JSON.parse(LocalStorage.getUserData());
  } catch (error) {
    userData = {}; // Default to an empty object if parsing fails
  }
  const role = userData?.role;
  const employeeId = userData?.employeeId;

  const baseHref =
    (role === roles.SENIOR_MANAGEMENT && ROUTES.SENIOR_MANAGEMENT) ||
    (role !== roles.HR && `/employee/${employeeId}`) ||
    ROUTES.EMPLOYEES;

  const projects = [
    {
      id: 1,
      name: LANDING_PAGE_TITLE.MY_DATA,
      description: LANDING_PAGE_TITLE_DESCRIPTION.MY_DATA,
      imageUrl: images.my_data,
      href: baseHref,
    },
    {
      id: 2,
      name: LANDING_PAGE_TITLE.MY_ISSUES,
      description: LANDING_PAGE_TITLE_DESCRIPTION.MY_ISSUES,
      imageUrl: images.my_issue,
      href: ROUTES.MY_ISSUES,
    },
    {
      id: 3,
      name: LANDING_PAGE_TITLE.MY_TASK,
      description: LANDING_PAGE_TITLE_DESCRIPTION.MY_TASK,
      imageUrl: images.my_task,
    },
    {
      id: 4,
      name: LANDING_PAGE_TITLE.MY_CARRER,
      description: LANDING_PAGE_TITLE_DESCRIPTION.MY_CARRER,
      imageUrl: images.my_career,
    },
    {
      id: 5,
      name: LANDING_PAGE_TITLE.MY_TIME_SHEET,
      description: LANDING_PAGE_TITLE_DESCRIPTION.MY_TIME_SHEET,
      imageUrl: images.my_time_sheet,
    },
    {
      id: 6,
      name: LANDING_PAGE_TITLE.WORK_FORCE_MANAGEMENT,
      description: LANDING_PAGE_TITLE_DESCRIPTION.WORK_FORCE_MANAGEMENT,
      imageUrl: images.work_force_management,
    },
  ];
  return (
    <div className="max-w-screen-xl mx-auto h-screen-16 overflow-hidden flex items-center md:relative md:top-8 justify-center">
      <div className="px-4 md:px-8 py-4 md:py-8 w-full max-w-screen-xl ">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
          {projects.map((project) => (
            <LandingCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
}
