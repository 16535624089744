import axios from "axios";
import LocalStorage from "../StorsgeUtil/LocalStorage";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../constants/messages";
import backendURL from "../../lib/api";

export const customFetch = axios.create({
  baseURL: backendURL,
});

customFetch.interceptors.request.use((config) => {
  const user = LocalStorage.isLoggedIn();
  if (user) {
    config.headers["Authorization"] = `Bearer ${LocalStorage.getToken()}`;
  }
  return config;
});

export const getHelper = async (
  url,
  setState,
  successMsg,
  errorMsg,
  params = {}
) => {
  try {
    if (typeof params !== "object" || params === null) {
      params = {};
    }
    const response = await customFetch.get(url, { params });

    if (successMsg) toast.success(successMsg);

    if (setState && typeof setState === "function") {
      setState(response.data.data);
    }

    return response.data;
  } catch (error) {
    console.error("API fetch error:", error);
    if (errorMsg) toast.error(errorMsg || "Failed to fetch data");
    return null;
  }
};

export const postHelper = async (url, data, successMsg, errorMsg) => {
  try {
    const response = await customFetch.post(url, data);
    toast.success(successMsg);
    return response.data.data;
  } catch (error) {
    console.error(error);
    toast.error(errorMsg || "Failed to post data");
    // throw error;
  }
};

export const editHelper = async (url, data, successMsg, errorMsg) => {
  try {
    const response = await customFetch.put(url, data);
    if (response.data.success) {
      toast.success(successMsg);
      return response.data;
    } else {
      throw new Error(response.data.message || errorMsg);
    }
  } catch (error) {
    toast.error(errorMsg);
    console.error("EditHelper error:", error);
    return { success: false, message: error.message || errorMsg };
  }
};

export const postFile = async (url, data, type, successMsg, errorMsg) => {
  const formData = new FormData();
  if (Array.isArray(data)) {
    data.forEach((file, index) => {
      formData.append(`${type}${index}`, file);
    });
  } else {
    formData.append(type, data);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const response = await customFetch.post(url, formData, config);
    return response.data;
  } catch (error) {
    toast.error(ERROR_MESSAGES.profilePhotoUpload);
  }
};

export const deleteFile = async (url, successMsg, errorMsg) => {
  try {
    await customFetch.delete(url);
  } catch (error) {}
};
