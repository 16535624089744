import React, { useContext } from "react";
import { dateFormatInput } from "../../utils/dateConverter";
import { API_FIELD_NAMES,  INPUT_TYPES } from "../../utils/constants/keywords";
import DropdownSelection from "./DropdownSelection";
import { GlobalContext } from "../../utils/context/Global/GlobalContext";
import { dataTransformIdName } from "../../utils/dataTransformIdName";
import { dropDownSelector } from "../../utils/dropDownSelector";
import DropdownSearchSelection from "./DropdownSearchSelection";
import CustomInputFile from "./CustomInputFile";

const TextInput = ({
  label,
  required,
  type,
  name,
  value,
  handleChange,
  maxLength,
  multipleFileSelection,
  acceptFileFormat,
  fileName,
  classname,
  icon,
  isDisable,
}) => (
  <>
    <div className={type === INPUT_TYPES.CHECKBOX && "flex items-center gap-2"}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 text-nowrap truncate text-left"
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      {(type === INPUT_TYPES.FILE && (
        <CustomInputFile
          id={name}
          handleChange={handleChange}
          multiple={multipleFileSelection}
          value={fileName}
          acceptFileFormat={acceptFileFormat}
          testid={`input_field-${name}`}
        />
      )) || (
        <div className="relative flex items-center mt-2">
          <span className="absolute">{icon}</span>
          <div className="flex flex-col w-full">
            <input
              type={type}
              required={INPUT_TYPES.FILE !== type && required}
              name={name}
              id={name}
              autoComplete="address-level2"
              className={`${
                classname ||
                "block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              }`}
              value={(type === INPUT_TYPES.DATE && dateFormatInput(value)) || value}
              checked={type === INPUT_TYPES.CHECKBOX && value}
              onChange={handleChange}
              maxLength={maxLength}
              multiple={multipleFileSelection}
              accept={acceptFileFormat}
              data-testid={`input_field-${name}`}
              disabled={isDisable}
            />
          </div>
        </div>
      )}
    </div>
  </>
);

const TextInputField = ({ input, value, handleChange, touched, errors, fileName, isFormSubmit, isDisable }) => {
  const {
    name,
    label,
    type,
    required,
    maxLength,
    select,
    selectionItems,
    multipleFileSelection,
    acceptFileFormat,
    classname,
    icon,
    isDisabled,
  } = input;
  const { enums } = useContext(GlobalContext);

  return (
    <div
      className={`  ${
        (type === INPUT_TYPES.FILE || type === INPUT_TYPES.CHECKBOX) && "sm:col-span-2 md:col-span-3 xl:col-span-4"
      }`}
    >
      {name !== API_FIELD_NAMES.SUPERVISOR &&
        ((!select && (
          <TextInput
            label={label}
            required={required}
            type={type}
            name={name}
            value={value}
            handleChange={handleChange}
            maxLength={maxLength}
            multipleFileSelection={multipleFileSelection}
            acceptFileFormat={acceptFileFormat}
            fileName={fileName}
            classname={classname}
            icon={icon}
            isDisable={isDisabled || isDisable}
          />
        )) || (
          <DropdownSelection
            selectionItems={dropDownSelector(enums, selectionItems)}
            label={label}
            value={value}
            onChange={handleChange}
            name={name}
            required={required}
            isDisable={isDisable}
          />
        ))}
      {name === API_FIELD_NAMES.SUPERVISOR && (
        <DropdownSearchSelection
          selectionItems={dataTransformIdName(enums.supervisors)}
          label={label}
          value={value}
          onChange={handleChange}
          name={name}
          required={required}
          isDisable={isDisable}
        />
      )}
      {isFormSubmit && errors && <div className="text-red-500 text-sm mt-2">{errors}</div>}
    </div>
  );
};

export default React.memo(TextInputField);
