import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../utils/constants/routes';
import LocalStorage from '../utils/StorsgeUtil/LocalStorage';
import Header from './Header';
import ScrollToTop from '../utils/Components/ScrollToTop';

const ProtectedRoutes = () => {
  const [authLogin, setAuthLogin] = useState(null);

  useEffect(() => {
    const checkLoginStatus = () => {
      const loggedIn = LocalStorage.isLoggedIn();
      setAuthLogin(loggedIn);
    };
    checkLoginStatus();
  }, []);

  if (authLogin === null) {
    return null;
  }

  return authLogin ? (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
    </>
  ) : (
    <Navigate to={ROUTES.LOGIN} replace />
  );
};

export default ProtectedRoutes;
