import React, { useState } from 'react';
import TextInputField from '../ReusableComponents/TextInputField';
import FormActionButtons from './FormActionButtons';
import { INPUT_TYPES } from '../../utils/constants/keywords';

const EmployeeInputFields = ({ inputs, formik, handleFormCancel }) => {
  const [isFormsubmit, setIsFormSubmit] = useState(false);
  const handleObjectChange = (event, objectName, name) => {
    const { checked, value, type } = event.target;

    const newValue = type === INPUT_TYPES.CHECKBOX ? checked : value;

    formik.setValues({
      ...formik.values,
      [objectName]: {
        ...formik.values[objectName],
        [name]: newValue,
      },
    });
  };
  return (
    <>
      <div
        className={`py-10 ${
          inputs.length !== 1 &&
          'grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'
        }`}>
        {inputs.map(input => (
          <TextInputField
            key={input.id}
            input={input}
            value={input.objectName && formik.values[input.objectName][input.name] || formik.values[input.name]}
            handleChange={input.objectName ? event => handleObjectChange(event, input.objectName, input.name) : formik.handleChange}
            touched={true}
            errors={formik.errors[input.name]}
            isFormSubmit={isFormsubmit}
            isDisable={
              (input.disableValue &&
                input.name !== input.disableValue.field &&
                (input.objectName
                  ? !formik.values[input.disableValue.obj]?.[input.disableValue.field]
                  : !formik.values[input.disableValue.field])) ||
              false
            }
          />
        ))}
      </div>
      <FormActionButtons handleFormCancel={handleFormCancel} setIsFormSubmit={setIsFormSubmit} />
    </>
  );
};
export default React.memo(EmployeeInputFields);
