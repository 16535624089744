import React from "react";
import { Link } from "react-router-dom";
import MultiDropdown from "../ReusableComponents/MultiselectDropdown";
import { getDurationText } from "../../utils/Components/dateUtils";
import Pagination from "../ReusableComponents/Pagination";
import { ERROR_MESSAGES } from "../../utils/constants/messages";

const AllIssueTable = ({
  filteredIssues,
  selectedFilters,
  REASSIGN_OPTIONS,
  PRIORITY_OPTIONS,
  STATUS_OPTIONS,
  DURATION_OPTIONS,
  handleFilterChange,
  isLoading,
  totalPages,
  pageNo,
  handlePageChange,
  PRIORITY_CLASSES,
  TABLE_HEADING,
  SENIOR_MANAGEMENT_PAGE_LABELS,
  ROUTES,
}) => {
  let tableBodyContent;

  if (isLoading) {
    tableBodyContent = (
      <tr>
        <td colSpan="9" className="text-center py-4">
          {ERROR_MESSAGES.LOADING_MESSAGE}
        </td>
      </tr>
    );
  } else if (filteredIssues.length === 0) {
    tableBodyContent = (
      <tr>
        <td colSpan="9" className="text-center py-4">
          {ERROR_MESSAGES.NO_ISSUES_FOUND}
        </td>
      </tr>
    );
  } else {
    tableBodyContent = filteredIssues.map((issue) => (
      <tr key={issue.issueId}>
        <td className="p-4 flex items-center gap-4">
          <img
            loading="lazy"
            src={issue.userImage}
            alt="Employee"
            className="w-10 h-10 rounded-full"
          />
          <div className="flex flex-col">
            <div className="font-medium text-gray-900">{issue.name}</div>
            <div className="text-gray-500">ID: {issue.employeeID}</div>
          </div>
        </td>
        <td className="p-4">{issue.issueId}</td>
        <td className="p-4">
          {new Date(issue.createdAt).toLocaleDateString("en-GB")}
        </td>
        <td className="p-4">{issue.assignedTo.department}</td>
        <td className="p-4 text-center">
          <div
            className={`px-2.5 py-0.5 ${
              PRIORITY_CLASSES[issue.priority]
            } rounded-md`}
          >
            {issue.priority}
          </div>
        </td>
        <td className="p-4">{issue.status}</td>
        <td className="p-4">
          {issue.status === "Closed" && issue.closedAt
            ? new Date(issue.closedAt).toLocaleDateString("en-GB")
            : "----------"}
        </td>
        <td className="p-4">{getDurationText(issue.createdAt)}</td>
        <td className="p-4">
          <Link
            to={`${ROUTES.VIEW_EMPLOYEEISSUES}/${issue.issueId}`}
            className="underline text-blue-700"
          >
            {TABLE_HEADING.VIEW}
          </Link>
        </td>
      </tr>
    ));
  }

  return (
    <div className="bg-white shadow-md rounded">
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {SENIOR_MANAGEMENT_PAGE_LABELS.EMPLOYEE_NAME}
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.ISSUE_ID}
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.RAISED_DATE}
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.ASSIGNED}
              <MultiDropdown
                options={REASSIGN_OPTIONS}
                selectedOptions={selectedFilters.assigned}
                onChange={(option) => handleFilterChange("assigned", option)}
              />
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.PRIORITY}
              <MultiDropdown
                options={PRIORITY_OPTIONS}
                selectedOptions={selectedFilters.priority}
                onChange={(option) => handleFilterChange("priority", option)}
              />
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {SENIOR_MANAGEMENT_PAGE_LABELS.STATUS}
              <MultiDropdown
                options={STATUS_OPTIONS}
                selectedOptions={selectedFilters.status}
                onChange={(option) => handleFilterChange("status", option)}
              />
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.CLOSED_DATE}
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {TABLE_HEADING.DURATION}
              <MultiDropdown
                options={DURATION_OPTIONS}
                selectedOptions={selectedFilters.duration}
                onChange={(option) => handleFilterChange("duration", option)}
              />
            </th>
            <th className="p-4 text-left text-xs font-semibold text-gray-500 uppercase">
              {SENIOR_MANAGEMENT_PAGE_LABELS.ACTIONS}
            </th>
          </tr>
        </thead>
        <tbody>{tableBodyContent}</tbody>
      </table>
      {totalPages >= 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageNo}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AllIssueTable;
