export const FIELD_LABELS = {
  PHOTO: "Photo",
  EMPLOYEE_ID: "Employee ID",
  EMPLOYEE_NAME: "Employee name",
  DEPARTMENT: "Department",
  CONTACT_NUMBER: "Contact number",
  EMAIL_ADDRESS: "E-mail address",
  DATE_OF_BIRTH: "Date of birth",
  FATHERS_NAME: "Father’s name",
  MOTHERS_NAME: "Mother’s name",
  MARITAL_STATUS: "Marital status",
  SPOUSE: "Spouse",
  NO_OF_CHILDREN: "Number of children",
  BLOOD_GROUP: "Blood group",
  EMERGENCY_CONTACT_NAME: "Emergency contact name",
  EMERGENCY_CONTACT_NUMBER: "Emergency contact number",
  NATIONALITY: "Nationality",
  AADHAR_NO: "Aadhar number",
  PAN_CARD_NO: "Pan card number",
  PASSPORT_NO: "Passport number",
  CURRENT_ADDRESS: "Current address",
  PERMANENT_ADDRESS: "Permanent address",
  EDUCATION: "Qualification",
  UNIVERSITY_BOARD: "University/Board",
  FIELD_OF_STUDY: "Specialization",
  START_DATE: "Start date",
  END_DATE: "End date",
  GRADES_PERCENTAGE: "Grades/Percentage",
  DOCUMENT_UPLOAD: "Document upload",
  CERTIFICATION_NAME: "Certification name",
  CERTIFICATION_YEAR: "Year",
  INSTITUTE: "Institute",
  DESIGNATION: "Designation",
  EMPLOYMENT_TYPE: "Employment type",
  CLIENT: "Client",
  TAGGING: "Tagging",
  STATUS: "Status",
  SUPERVISOR: "Reporting manager",
  MY_PROJECT: "My project",
  TOTAL_RELEVANT_EXPERIENCE: "Total relevant years of experience",
  TOTAL_YEAR_EXPERIENCE: "Total years of experience",
  COMPANY_NAME: "Company name",
  COMPANY_LOCATION: "Company location",
  WORK_MODE: "Work mode",
  BAND_LEVEL: "Band level",
  CTC_IN_LPA: "CTC in LPA",
  CURRENT_CTC_IN_LPA: "Current CTC in LPA",
  PREVIOUS_CTC_IN_LPA: "Previous CTC in LPA",
  TRAINING_NAME: "Training name",
  TRAINING_STATUS: "Status",
  DATE_OF_COMPLETION: "Date of completion",
  BANK_NAME: "Bank name",
  BANK_ACCOUNT_NO: "Bank account number",
  BRANCH: "Branch",
  IFSC: "IFSC",
  PF_NUMBER: "PF number",
  UAN: "UAN",
  PF_DOJ: "PF join date",
  SKILLSET: "Skill sets",
  STREAM: "Stream",
  LOCATION: "Location",
  EXPERT_SKILL: "Expert skill set",
  INTERMEDIATE_SKILL: "Intermediate skill set",
  BASIC_SKILL: "Basic skill set",
  UPLOADED_DOCUMENTS: "Upladed documents",
  DOCUMENTS: "Documents",
  NAME_AS_PER_BANK: "Name as per bank",
  UPLOADED_DOCUMENT: "Uploaded documents",
  SELECT_FILE: "Selected file:",
  GENDER: "Gender",
  OFFER_LETTER_UPLOAD: "Offer letter upload",
  RELIEVING_LETTER_UPLOAD: "Relieving & experience letter upload",
  PAY_SLIP_UPLOAD: "Last 3 pay slip upload",
  ESI_NUMBER: "ESI number",
  UNDER_ESI: "Employee is covered under ESI",
  UNDER_INSURANCE: "Employee is covered under insurance",
  POLICY_NUMBER: "Policy number",
  CARD_NUMBER: "Card number",
  NAME: "Name",
  UNDER_PF: "Employee is covered under PF",
  RELATION: "Relation with the employee",
  DOCUMENT_TYPE: "Document type",
  EXIST_EPF_MEMBER: "Is existing member of EPS",
  EXIST_EPF_CONTRIBUTION: "Allow EPF excess contribution",
  EXIST_EPS_CONTRIBUTION: "Allow EPS excess contribution",
  UNDER_LWF :'Employee is covered under LWF',
  SUBJECT: "Subject",
  ISSUE_DESCRIPTION: "Issue Description",
  ASSIGN: "Assign",
  PRIORITY: "Priority",
  ATTACHMENTS: "Attachments",
  VIEW_ATTACHMENT: "View Attachment",
  NO_ATTACHMENT: "No attachment provided",
  DOC_LINK: "Document Link",
  COMMENTS: "Comments",
  NO_COMMENTS: "No Comments yet",
  ADD_COMMENT_PLACEHOLDER: "Add a comment...",
  NA: "N/A",
  ATTACH_FILE: "Attach a File",
  CROSS_SYMBOL: "X",
  DUPLICATE_FILE: "This file is already attached.",
  INVALID_FILE_FORMAT:
    "Invalid file format. Please attach JPEG, DOCX, PNG, PDF, or XLSX files.",
};

export const INPUT_TYPES = {
  TEXT: "text",
  TEL: "tel",
  EMAIL: "email",
  DATE: "Date",
  NUMBER: "number",
  NUMERIC: "numeric",
  FILE: "file",
  IMAGE: "image",
  MONTH: "month",
  CHECKBOX: "checkbox",
  LINK:"link"
};

export const TABLE_FIELD_TYPE = {
  TEXT: "text",
  LINK: "link",
  FUNCTION: "function",
  STATUS: "status",
};

export const API_FIELD_NAMES = {
  PROFILE_PHOTO: "profilePhoto",
  EMPLOYEE_ID: "employeeId",
  EMPLOYEE_NAME: "name",
  DEPARTMENT: "role",
  CONTACT_NUMBER: "contactNumber",
  EMAIL_ADDRESS: "email",
  DATE_OF_BIRTH: "dateOfBirth",
  FATHERS_NAME: "fatherName",
  MOTHERS_NAME: "motherName",
  MARITAL_STATUS: "maritalStatus",
  SPOUSE: "spouse",
  NO_OF_CHILDREN: "noOfChildren",
  BLOOD_GROUP: "bloodGroup",
  EMERGENCY_CONTACT_NAME: "emergencyContactName",
  EMERGENCY_CONTACT_NUMBER: "emergencyContactNumber",
  NATIONALITY: "nationality",
  AADHAR_NO: "aadhaarNo",
  PAN_CARD_NO: "panCardNo",
  PASSPORT_NO: "passportNo",
  CURRENT_ADDRESS: "currentAddress",
  PERMANENT_ADDRESS: "permanentAddress",
  EDUCATION: "level",
  UNIVERSITY_BOARD: "university",
  FIELD_OF_STUDY: "specialization",
  START_DATE: "startDate",
  END_DATE: "endDate",
  GRADES_PERCENTAGE: "percentage",
  DOCUMENT_UPLOAD: "documentUpload",
  EDUCATIONAL_DOCUMENTS: "educationalDocuments",
  CERTIFICATION_DOCUMENTS: "certificationDocuments",
  OFFER_LETTER_DOCUMENTS: "offerLetter",
  EXPERIENCE_LETTER_DOCUMENTS: "relievingLetter",
  PAYSLIP_DOCUMENTS: "paySlip",
  EMPLOYMENT_DOCUMENTS: "employmentDocuments",
  CERTIFICATION_NAME: "certificationName",
  CERTIFICATION_YEAR: "certificationYear",
  INSTITUTE: "institute",
  DESIGNATION: "designation",
  EMPLOYMENT_TYPE: "employmentType",
  CLIENT: "client",
  TAGGING: "tagging",
  STATUS: "status",
  SUPERVISOR: "supervisor",
  MY_PROJECT: "myProject",
  TOTAL_RELEVANT_EXPERIENCE: "relevantYearExperience",
  TOTAL_YEAR_EXPERIENCE: "totalYearExperience",
  COMPANY_NAME: "companyName",
  COMPANY_LOCATION: "companyLocation",
  WORK_MODE: "workMode",
  BAND_LEVEL: "bandLevel",
  CTC_IN_LPA: "ctcInLPA",
  CURRENT_CTC_IN_LPA: "currentCtc",
  PREVIOUS_CTC_IN_LPA: "previousCtc",
  TRAINING_NAME: "trainingName",
  TRAINING_STATUS: "trainingStatus",
  DATE_OF_COMPLETION: "completionDate",
  BANK_NAME: "bankName",
  BANK_ACCOUNT_NO: "bankAccountNo",
  BRANCH: "branch",
  IFSC: "ifsc",
  PF_NUMBER: "pfNumber",
  UAN: "uan",
  PF_DOJ: "pfJoining",
  SKILL_SET: "skillSet",
  SPECIALIZATION_OF_STUDY: "Specialization of Study",
  CERTIFICATION_DETAILS: "Certification Details",
  EMPLOYMENT_DETAIL: "Employment Detail",
  MY_TRAINING: "training",
  ACCOUNT_DETAILS: "Account Details",
  BANK_DETAILS: "Bank Details",
  PF_ACCOUNT_DETIALS: "Statutory Details",
  EDUCATION_OBJ: "education",
  CERTIFICATION_OBJ: "certification",
  SKILLSET_OBJ: "Skill Sets",
  CURRENT_EMPLOYMENT_OBJ: "currentEmployment",
  PREVIOUS_EMPLOYMENT_OBJ: "previousEmployments",
  PROFILE: "Profile",
  PERSONAL: "Personal",
  IMAGE_UPLOAD: "imageUpload",
  STREAM: "stream",
  LOCATION: "location",
  EXPERT_SKILL: "expert",
  INTERMEDIATE_SKILL: "intermediate",
  BASIC_SKILL: "basic",
  NAME_AS_PER_BANK: "nameAsPerBank",
  SUBJECT: "subject",
  ISSUE_ID: "issueId",
  BUSSINESS_SUPPORT: "Bussiness Support",
  GENDER: "gender",
  ESI_NUMBER: "esiNumber",
  UNDER_ESI: "underEsi",
  ESI_ACCOUNT: "ESI Account",
  INSURANCE_DETAILS: "Insurance Details",
  UNDER_INSURANCE: "underInsurance",
  POLICY_NUMBER: "policyNumber",
  CARD_NUMBER: "cardNumber",
  NAME: "name",
  UNDER_PF: "underPf",
  RELATION: "relation",
  FAMILY_DETAILS: "Family Details",
  DOCUMENT_TYPE: "documentType",
  EXIST_EPF_MEMBER: "existingEpfMember",
  EXIST_EPF_CONTRIBUTION: "excessEpfContribution",
  EXIST_EPS_CONTRIBUTION: "excessEpsContribution",
  LABOUR_WELFARE_FUND: "Labour Welfare Fund",
  UNDER_LWF :'underLwf',
  FAMILY_DETAILS_OBJ: "familyDetail",
  STATUTORY:"statutory",
  INSURANCE_OBJ : "insurance",
};
export const API_FIELD_VALUES = {
  INTERNAL: "Internal",
  DEPARTMENT_EMPLOYEE_NAME: "employeeName",
  DEPARTMENT_ISSUE_ID: "id",
  GENDER: "gender",
};

export const FIELD_HEADING = {
  PROFILE: "Profile",
  PERSONAL_DETAILS: "Personal details",
  EDUCATION_DETAILS: "Education Details",
  CERTIFICATION_DETAILS: "Certification Details",
  SKILLSET: "Skill Sets",
  EMPLOYMENT_DETAILS: "Employment Details",
  PREVIOUS_EMPLOYMENT_DETAILS: "Employment details",
  MY_TRAINING: "My Training",
  BANK_DETAILS: "Bank Details",
  PF_ACCOUNT_DETAILS: "Statutory Details",
  ACCOUNT_DETAILS: "Account Details",
  ESI_ACCOUNT: "ESI Account",
  FAMILY_DETAILS: "Family Details",
  LABOUR_WELFARE_FUND: "Labour Welfare Fund",
  INSURANCE_DETAILS: "Insurance Details",
};

export const FIELD_HEADING_DESCRIPTION = {
  PROFILE: "",
  PERSONAL_DETAILS: "",
  EDUCATION_DETAILS: "(Highest education of the employee)",
  CERTIFICATION_DETAILS: "(Additional if any)",
  SKILLSET: "",
  EMPLOYMENT_DETAILS: "(Current employment details)",
  PREVIOUS_EMPLOYMENT_DETAILS: "(Previous employment details)",
  MY_TRAINING: "",
  ESI_ACCOUNT: "",
  BANK_DETAILS: "(Employee bank details)",
  PF_ACCOUNT_DETAILS: "",
  INSURANCE_DETIALS: "",
  FAMILY_DETAILS: "",
  LABOUR_WELFARE_FUND: "",
  INSURANCE_DETAILS: "",
};

export const DEFAULT_SELECT = "Select";
export const INCOMPLETE_SELECT = "InComplete";

export const EMPLOYEE_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const BREADCRUMB_STATUS = {
  CURRENT: "current",
  UPCOMMING: "upcomming",
  COMPLETE: "complete",
  ERROR: "error",
};

export const LANDING_PAGE_TITLE = {
  MY_DATA: "My Data",
  MY_ISSUES: "My Issues",
  MY_TASK: "My Task",
  MY_CARRER: "My Career",
  MY_TIME_SHEET: "My Time Sheet",
  WORK_FORCE_MANAGEMENT: "Work Force Mangement",
};

export const LANDING_PAGE_TITLE_DESCRIPTION = {
  MY_DATA: "Check your data here and get update",
  MY_ISSUES: "Submit and Track Tickets",
  MY_TASK: "Stay Organized with Your To-Do List",
  MY_CARRER: "Track your appraisal and your progression",
  MY_TIME_SHEET: "Track Your Time",
  WORK_FORCE_MANAGEMENT: "Optimize Your Workforce",
};

export const PROJECT_TITLE = "Drishti";

export const LOGIN_PAGE_LABELS = {
  SIGN_IN_TITLE: "Sign In to Dristhi",
  MAIL: "Email",
  PASSWORD: "Password",
  FORGOT_PASSWORD: "Forgot password?",
  SIGN_IN_BUTTON: "Sign in",
  FORGOT_PASSWORD_TITLE: "Enter Email",
  FORGOT_PASSWORD_DESCRIPTION: "Enter your email address to receive a password",
  LOGOUT: "Logout",
  IMG_ALT: "Logo to be added here",
};

export const RESET_PASSWORD_PAGE_LABELS = {
  TITLE: "Reset Password",
  DESCRIPTION: "Your new password must be different from your previous passwords.",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
};

export const ACTION_BUTTONS = {
  SUBMIT: "Submit",
  CANCEL: "Cancel",
  NEXT: "Next",
  BACK: "Back",
  CHANGE: "Change",
  PREVIOUS: "Previous",
  CREATE: "Create +",
  VIEW: "View Details",
  SAVE: "Save",
  ADD: "+ ADD",
  REASSIGN: "Reassign",
  COMPLETE: "Completed",
  ACCEPT: "Accept",
  DECLINE: "Decline",
  WITHDRAW: "Withdraw",
  CLOSE: " Close Issue",
  NEW_ISSUES: "NEW ISSUES",
  REOPEN: "Reopen",
  REMOVE : "Remove",
  APPROVE : "Approve",
  CONSENT: "Consent Form",
  NEW_EMPLOYEE: "New Employee +",
  SEND: "Send",
  OK: "Okay",
  VIEW_HISTORY : "View History",
  CHOOSE_FILE : "Choose file"
};

export const roles = {
  HR: "HR",
  FINANCE: "FINANCE",
  IT_SUPPORT: "IT SUPPORT",
  EMPLOYEE: "Employee",
  ADMIN: "Admin",
  DELIVERY_EXCELLENCE: "DELIVERY EXCELENCE",
  TAG: "TAG",
  SALES: "SALES",
  MARKETING: "MARKETING",
  SENIOR_MANAGEMENT: "Senior Management",
};

export const SENIOR_MANAGEMENT_PAGE_LABELS = {
  EMPLOYEE_NAME: "Employee Name & ID",
  SUPERVISOR: "SUPERVISOR",
  DEPARTMENT: "DEPARTMENT",
  COST_CENTER: "COST CENTER",
  TAGGING: "TAGGING",
  STATUS: "STATUS",
  ACTIONS: "ACTION",
  TOTAL_ISSUES: "Total Issues",
};

export const CONSENT_FORM_FIELD_NAMES = {
  EMPLOYEE_NAME: "EMPLOYEE NAME",
  DATE_SENT: "DATE SENT",
  SENT_BY: "SENT BY",
  STATUS: "STATUS",
  ACTION: "ACTION",
};

export const CONSENT_FORM_ACTION_BUTTONS = {
  RESNED_EMAIL: "Resend email",
  CREATE_EMPLOYEE: "Create employee",
};

export const MODAL_TYPE = {
  INPUT: "input",
  SUCCESS_RES:"SUCCESS",
  ERROR_RES : "ERROR",
  MANUAL : "MANUAL"
}

export const CONSENT_FORM_API_FIELD_NAMES = {
  EXPIRY_DATE: "consentExpiryDate",
  GIVEN: "consentGiven",
  SENT: "consentSent",
  SENT_DATE: "consentSentDate",
  PARENT_EMAIL: "parentEmail",
  PRODUCT_NAME: "productName",
  USER_AGE: "userAge",
  USER_EMAIL: "userEmail",
  USER_NAME: "userName",
  DOB: "DOB",
  SENT_BY: "sentBy",
};
export const PAGINATION = {
  ISSUE_PER_PAGE: "10",
};
export const TABLE_HEADING = {
  ISSUE_ID: "ISSUE ID",
  RAISED_DATE: "RAISED DATE",
  PRIORITY: "PRIORITY",
  CLOSED_DATE: "CLOSED DATE",
  VIEW: "View",
  ISSUES: "Issues",
  ASSIGNED: "Assigned",
  DURATION: "Duration",
};

export const DAYS_COUNT = {
  TODAY: "Today",
  ONE_DAY_AGO: "One day ago",
  DAYS_AGO: "days ago",
};

export const DURATION_TEXT = {
  LESS_THAN_ONE_DAY: "24 hours",
  ONE_TO_THREE_DAYS: "1-3 days",
  FOUR_TO_SEVEN_DAYS: "4-7 days",
  MORE_THAN_SEVEN_DAYS: "Above 7 days",
};

export const STATUS = {
  CLOSED: "Closed",
  OPEN: "Open",
  INPROGRESS: "InProgress",
  DECLINED: "Decline",
  WITHDRAW: "Withdrawn",
  COMPLETED: "Completed",
  REOPENED: "Reopened",
  ACCEPTED : "Accepted",
  REJECTED : "Rejected",
  PENDING :"Pending"
};

export const PRIORITY = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};

export const employeeIssuesTitle = {
  empIssuesTitle: "Employees Issues",
  empIssuesSubTitle: "This is the list of employees and their Issues",
};

export const employeeList = {
  empList: "Employee List",
  empListDesc: "This is the list of the employees and their details",
};

export const CONSENT = {
  title: "Consent Form",
  description: "Enusure employees have double opt-in",
  SUCCESS: "Success",
  SEND_CONSENT: "Send Consent Form",
};

export const API_RESPONSE_STATUS = {
  SUCCESS: "Success",
  ERROR: "error",
};

export const STATS_LABELS = {
  TOTAL_EMPLOYEES: "Total No. of employee’s",
  BILLABLE: "Billable",
  NON_BILLABLE: "NonBillable",
  BENCH: "Bench",
  INVESTMENT: "Investment",
  NAD: "NAD",
  DNB: "DNB",
  BUSSINESS_SUPPORT: "Business support",
  MANAGEMENT: "Management",
};

export const PATH_KEYWORDS = {
  EMPLOYEE: "employee",
  SENIOR_MANAGEMENT_EMPLOYEE_VIEW: "seniorManagementEmployeeView",
  ISSUES: "issues",
  EMPLOYEE_ISSUE_DETAILS: "employeeissuedetails",
};

export const NOT_AVAILABLE = "N/A";

export const STORAGE_UTIL_KEYWORDS = {
  IS_LOGGED_IN: "isLoggedIn",
  USER_DATA: "userData",
  TOKEN: "token",
};

export const enumNames = {
  BAND_LEVELS: "BandLevel",
  BLOOD_GROUPS: "BloodGroup",
  CLIENTS: "Client",
  EDUCATION_LEVELS: "EducationLevel",
  EMPLOYMENT_STATUSES: "Status",
  EMPLOYMENT_TYPES: "EmploymentType",
  ISSUE_STATUSES: "IssueStatus",
  MARITAL_STATUSES: "MaritalStatus",
  PRIORITIES: "Priorities",
  PROJECTS: "Project",
  CAREER_STREAMS: "streams",
  TAGGINGS: "Tagging",
  TRAINING_STATUSES: "trainingStatus",
  USER_ROLES: "Role",
  WORK_MODES: "workMode",
  STREAM: "Stream",
  GENDER: "Gender",
  TRAINING_NAME: "TrainingName",
  TRAINING_STATUS: "TrainingStatus",
  DOCUMENT_TYPE: "DocumentType",
};
