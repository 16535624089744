export const employeeDetails = {
  name: "Employee Name",
  id: "Employee Id",
  contact: "Employee Contact No.",
  email: "Employee email",
  dob: "Date of Birth",
  father: "Father's Name",
  mother: "Mother's Name",
  maritalState: "Marital Status",
  spouse: "Spouse's Name",
  children: "No. of Children",
  bloodGroup: "Blood Group",
  emergencyName: "Emergency Contact Name",
  emergencyNumber: "Emergency Contact No.",
  nation: "Nationality",
  adhar: "Adhar No.",
  pan: "PAN Card No.",
  passport: "Passport No.",
  add: "Adress",
  education: "Education",
  university: "University",
  stream: "Field of Study",
  startDate: "Start Date",
  endDate: "End Date",
  grade: "Grade/Percentage",
};
export const employeeCard = {
  id: "Employee ID",
  client: "Client",
  status: " Status",
  tag: "Tagging",
  view: "View Details",
};
export const employeeList = {
  empList: "Employee List",
  empListDesc: " This is the list of the employees and their details",
};
export const employeeIssues = {
  empIssues: "My Issues",
  myIssuesSubTitle: "Ongoing and closed issues",
};

export const employeeDetailIssues = {
  empIssuesDetail: "My Issue Details",
  myIssuesSubTitleDetail: "This is employees Issues in detail",
};

export const employeeIssuesTitle = {
  empIssuesTitle: "Employees Issues",
  empIssuesSubTitle: "This is the list of employees and their Issues",
};

export const AllDepartment = {
  DepartmentIssues: "All Department Employees Issues",
  DepartmentSubTitle: "Ongoing and closed issues",
};
