import React, { useState, useEffect, useRef } from "react";
import { FILTER_ICON } from "../../utils/Components/imageUrls";

const MultiDropdown = ({
  options,
  selectedOptions,
  onChange,
  ariaLabel,
  id,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (key, value) => {
    onChange(key, value); // Pass the change up to the parent component
  };

  return (
    <div
      className="relative inline-flex"
      ref={dropdownRef}
      aria-labelledby={id}
    >
      <div
        className="text-xs font-semibold text-gray-500 uppercase bg-gray-50 cursor-pointer"
        onClick={toggleDropdown}
      >
        <img
          loading="lazy"
          src={FILTER_ICON}
          className="shrink-0 aspect-square w-[15px] inline-flex"
          alt="Filter Icon"
        />
      </div>
      {isDropdownVisible && (
        <div className="absolute z-10 mt-2 w-40 bg-white border border-gray-200 rounded shadow-lg">
          {options.map((option) => (
            <div key={option} className="p-2">
              <input
                type="checkbox"
                id={`status-${option}`}
                checked={!!selectedOptions[option]}
                onChange={(e) => handleChange(option, e.target.checked)}
              />
              <label htmlFor={`status-${option}`} className="ml-2">
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiDropdown;
