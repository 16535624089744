import React from "react";
import EmployeeViewField from "./EmployeeViewField";
import { viewPageInputs } from "./viewPageInputs";
import SectionHeader from "./SectionHeader";
import EmployeeSkillsInputField from "../CreateEmployee/EmployeeSkillsInputField";
import FormActionButtons from "./FormActionButtons";
import Button from "../ReusableComponents/Button";
import { PencilIcon } from "@heroicons/react/24/outline";

const EditableUserSkillDetailSection = ({
  sectionTitle,
  handleAddDetail,
  formik,
  handleFormCancel,
  inputs,
  editSelection,
  handleEdit,
  hideEdit,
  hideAdd = true,
  handleUpdateSkill,
  setIsFormSubmit = () => {},
}) => {
  const isArraySection = Array.isArray(formik.values?.[sectionTitle]);
  return (
    <div className="relative group px-5  sm:px-20">
      <SectionHeader
        sectionObj={viewPageInputs[sectionTitle]}
        sectionName={sectionTitle}
        hideEdit={hideEdit || editSelection[viewPageInputs[sectionTitle].id].isVisible === true}
        addButton={hideAdd && isArraySection && editSelection[viewPageInputs[sectionTitle].id].isVisible === true}
        handleAddDetail={handleAddDetail}
        handleEdit={handleEdit}
      />

      {(editSelection[viewPageInputs[sectionTitle].id].isVisible === false && (
        <EmployeeViewField
          key={viewPageInputs[sectionTitle].id}
          field={viewPageInputs[sectionTitle].fields}
          employee={formik.values.skillSet}
        />
      )) || (
        <>
          {
            <>
              <EmployeeSkillsInputField
                inputs={inputs}
                value={formik.values.skillSet}
                handleUpdateSkill={handleUpdateSkill}
                formik={formik}
              />
              <FormActionButtons handleFormCancel={handleFormCancel} setIsFormSubmit={setIsFormSubmit} />
            </>
          }
        </>
      )}
      {hideEdit || editSelection[viewPageInputs[sectionTitle].id]?.isVisible || (
        <div className="absolute top-24 right-20 hidden  group-hover:block">
          <Button
            testId={`${viewPageInputs[sectionTitle]?.heading?.label}-edit_button`}
            children={<PencilIcon className="h-4 w-4" />}
            onClick={() => handleEdit(viewPageInputs[sectionTitle]?.id)}
            variant={"edit"}
          />
        </div>
      )}
    </div>
  );
};

export default EditableUserSkillDetailSection;
