"use client";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ACTION_BUTTONS } from "../../utils/constants/keywords";

function Button({ type, href, onClick, children, variant, disabled, backNavigation, testId }) {
  const navigate = useNavigate();
  const baseClasses = "cursor-pointer ";
  const variants = {
    cancel: "px-5 lg:px-9 py-3 text-sm font-medium text-backButton",
    save: "px-5 lg:px-9 py-3 text-sm font-medium rounded bg-backButtonBg text-white shadow-sm bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
    next: "px-5 lg:px-9 py-3 text-sm font-medium rounded bg-backButtonBg text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
    back: "px-5 lg:px-9 py-3 text-sm font-medium text-backButton",
    [ACTION_BUTTONS.ADD]: "text-base text-addButton font-normal cursor-pointer whitespace-nowrap",
    edit: "w-7 h-7 border rounded-full flex justify-center items-center text-addButton",
    Remove: "text-red-500 font-medium underline",
  };

  const classes = `${baseClasses} ${variants[variant]} ${disabled ? "cursor-not-allowed opacity-50" : ""}`;

  if (type === "link") {
    return (
      <Link to={href}>
        <span className={classes}>{children}</span>
      </Link>
    );
  }

  if (type === "back") {
    return (
      <button
        data-testid={testId}
        type="button"
        className={classes}
        onClick={() => navigate(backNavigation)}
        disabled={disabled}>
        {children}
      </button>
    );
  }

  return (
    <button
      data-testid={testId}
      type={type === "submit" ? "submit" : "button"}
      className={classes}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
}

export default Button;
