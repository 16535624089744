import React from "react";

const Loader = ({ size = "12", color = "blue-500" }) => {
  return (
    <div className="flex justify-center items-center py-10">
      <div
        className={`loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-${size} w-${size}`}
      ></div>
      <style jsx>{`
        .loader {
          border-top-color: #${color};
          animation: spinner 1.5s linear infinite;
        }
        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
