export const ERROR_MESSAGES = {
  SUBJECT_REQUIRED: "Please enter Subject line",
  SUBJECT_MAX_LENGTH: "Subject must be 50 characters or less",
  DESCRIPTION_REQUIRED: "Please enter Description",
  DESCRIPTION_MAX_LENGTH: "Description must be 200 characters or less",
  PRIORITY_INVALID: "Invalid priority",
  PRIORITY_REQUIRED: "Please select a priority",
  SELECT_DEPARTMENT: "Please select a department",
  ISSUE_CREATION_FAILED: "Failed to create an issue",
  COMMENT_ERROR: "An error occurred while adding the comment.",
  COMPLETE_ERROR:
    "You must add a comment before marking the issue as completed.",
  DECLINE_ERROR: "You must add a comment before declining the issue.",
  REASSIGN_ERROR: "You must add a comment before reassigning the issue.",
  COMMENT_EMPTY_ERROR: "Comment cannot be empty.",
  FETCH_ERROR: "An error occurred while fetching the data.",
  UPDATE_STATUS_ERROR: "An error occurred while updating the issue status.",
  IMAGE_PARSE_ERROR: "Failed to parse user image. Using default image.",
  FAILED_UPDATE: "Failed to update an issue.",
  WITHDRAW_REQUIRED: "Comment is required to Withdraw the issue.",
  WITHDRAW_ERROR: "Failed to withdraw the issue.",
  CLOSE_REQUIRED: "Comment is required to close the issue.",
  CLOSE_ERROR: "Failed to close the issue.",
  REOPEN_REQUIRED: "Comment is required to Reopen the issue.",
  REOPEN_ERROR: "Failed to Reopen the issue.",
  FETCH_ISSUES_ERROR: "Failed to fetch issues",
  ISSUE_ID_UNDEFINED: "Issue ID is undefined",
  FAILED_FETCH_ENUMS: "Error fetching ENUMS:",
  COMMENT_MANDATORY_FOR_ACTION:
    "Please add a comment before proceeding with this action",
  FAILED_ACTION_MESSAGE: "Please add a comment before performing this action.",
  NO_USER_ROLE: "userRole unavailable",

  FETCH_DATA_ERROR: "Failed to fetch",
  NO_EMPLOYEE_ID_FOUND: "No employee ID found for logged-in user.",
  UNAUTHORIZED_ACCESS: "Unauthorized access",
  LOGIN_VALID_EMAIL: "Please enter a valid email address",
  RESET_PASSWORD_REQ: "Password reset request failed.",
  VALID_PASSWORD: "Enter a valid password",
  CURRENT_PASSWORD_REQ: "Current password is required",
  NEW_PASSWORD_REQ: "New password is required",
  PASSWORD_CONDITION_FAILED:
    "Password must contain at least one uppercase letter, one number, one special character, and be at least eight characters long",
  PASSWORD_MATCH: "Passwords must match",
  TRY_AGAIN: "Something went wrong, please try again",
  INVALID_DATA_ERROR: "Invalid data structure received from API",

  LOADING_MESSAGE: "Loading...",
  NO_ISSUES_FOUND: "No issues found",

  profilePhoto: {
    required: "Profile photo is required",
  },
  profilePhotoUpload: "Image upload failed",
  documentUpload: "File upload failed",
  profilePhotoAlt: "Profile picture",
  displayPicture: "DP",
  employeeId: {
    required: "Employee ID is required",
    min: "Employee ID must be at least 3 characters long",
  },
  name: {
    required: "Name is required",
  },
  contactNumber: {
    required: "Contact number is required",
    matches: "Contact number must start with 9 or 6 and be 10 digits long",
  },
  role: {
    required: "Role is required",
    "is-not-select": "Role is required",
  },
  email: {
    required: "Email is required",
    email: "Please enter a valid email",
  },
  dateOfBirth: {
    required: "Date of birth is required",
  },
  fatherName: {
    required: "Father name is required",
  },
  motherName: {
    required: "Mother name is required",
  },
  gender: {
    required: "Gender is required",
    "is-not-select": "Gender is required",
  },
  maritalStatus: {
    required: "Marital Status is required",
    "is-not-select": "Marital Status is required",
  },
  bloodGroup: {
    required: "Blood group is required",
    "is-not-select": "Blood group is required",
  },
  emergencyContactName: {
    required: "Emergency contact name is required",
  },
  emergencyContactNumber: {
    required: "Emergency contact number is required",
    matches:
      "Emergency contact number must start with 9 or 6 and be 10 digits long",
  },
  nationality: {
    required: "Nationality is required",
  },
  aadhaarNo: {
    required: "Aadhar number is required",
    matches: "Aadhar number must be exactly 12 digits",
  },
  panCardNo: {
    required: "Pan card number is required",
    matches: "Invalid PAN card format",
  },
  passportNo: {
    required: "Passport number is required",
  },
  currentAddress: {
    required: "Current Address is required",
  },
  permanentAddress: {
    required: "Permanent Address is required",
  },
  nameAsPerBank: {
    required: "Name as per bank is requiured",
  },
  bankName: {
    required: "Bank name is required",
  },
  bankAccountNo: {
    required: "Bank account number is required",
    matches: "Invalid bank account number",
  },
  branch: {
    required: "Branch is required",
  },
  ifsc: {
    required: "IFSC is required",
  },
  pfNumber: {
    required: "PF number is required",
  },
  uan: {
    required: "UAN is required",
  },
  pfJoining: {
    required: "PF joining date is required",
  },
  cardNumber: {
    required: "Card Number is required",
  },

  education: {
    level: {
      required: "Education level is required",
      "is-not-select": "Education level is required",
    },
    university: {
      required: "University is required",
    },
    specialization: {
      required: "Specialization is required",
    },
    startDate: {
      required: "Start date is required",
    },
    endDate: {
      required: "End date is required",
      min: "End date cannot be before start date",
    },
    percentage: {
      required: "Grades/Percentage is required",
      typeError: "Grades/Percentage must be a number",
    },
    educationalDocuments: {
      required: "Document is required",
      typeError: "Document must not be empty",
    },
  },

  certification: {
    certificationName: {
      required: "Certification name is required",
    },
    institute: {
      required: "Institute name is required",
    },
    certificationYear: {
      required: "Certification year is required",
    },
  },
  skillSet: {
    expert: {
      required: "Expert skill is required",
    },
  },

  previousEmployments: {
    designation: {
      required: "Designation is required",
    },
    companyName: {
      required: "Company name is required",
    },
    startDate: {
      required: "Start date is required",
    },
    endDate: {
      required: "End date is required",
      min: "End date cannot be before start date",
    },
    ctcInLPA: {
      typeError: "CTC must be a number",
    },
  },

  currentEmployment: {
    designation: {
      required: "Designation is required",
    },
    employmentType: {
      required: "Employment type is required",
      "is-not-select": "Employment type is required",
    },
    client: {
      required: "Client is required",
      "is-not-select": "Client is required",
    },
    tagging: {
      required: "Tagging is required",
      "is-not-select": "Tagging is required",
    },
    status: {
      required: "Status is required",
      "is-not-select": "Status is required",
    },
    myProject: {
      required: "My project is required",
      "is-not-select": "My project is required",
    },
    supervisor: {
      required: "Supervisor is required",
    },
    location: {
      required: "Location is required",
    },
    startDate: {
      required: "Start date is required",
    },
    endDate: {
      required: "End date is required",
      min: "End date cannot be before start date",
    },
    stream: {
      required: "Stream is required",
    },
    bandLevel: {
      required: "Band level is required",
    },
    currentCtc: {
      required: "Current CTC in LPA is required",
      typeError: "Current CTC must be a number",
    },
    previousCtc: {
      required: "Previous CTC in LPA is required",
      typeError: "Previous CTC must be a number",
    },
    totalYearExperience: {
      typeError: "Total years of experience must be a number",
    },
    relevantYearExperience: {
      typeError: "Total Relevant years of experience must be a number",
      min: "Relevant experience must be less than or equal to total experience.",
    },
  },
  esiNumber:{
    required: "ESI number is required",
  },
  ADD_COMMENT: "Please add comment before assigning to other department",

  UNKNOWN_ERROR: "Unknown error occurred during reassignment",
  FUNCTION_ERROR: "onReassign is not a function",
  CONSENT_SENT: "Unable to send consent. Please try again later.",
  NO_FILE_CHOOSEN:"No file chosen"
};
export const SUCCESS_MESSAGES = {
  PROFILE_UPDATE: "Successfully updated MyData",
  PROFILE_CREATE: "Successfully created MyData",
  RESET_PASSWORD: "Password reset successfully.",

  REASSIGN_SUCCESS: "Issue reassigned successfully",
  COMMENT_SUCCESS: "Comment added successfully!",
  ISSUE_CREATED: "Issue created successfully",
  ISSUE_UPDATE: "Issue updated successfully!",
  WITHDRAW_SUCCESS: "Issue withdrawn successfully!",
  CLOSE_SUCCESS: "Issue closed successfully!",
  REOPEN_SUCCESS: "Issue Reopened successfully!",
  CONSENT_SENT: "Consent sent successfully.",
  CONSENT_FORM_SENT: "Consent form has been sent to",


};

export const NO_DATA_FOUND = "No data found.";
