import React, { useContext, useState, useEffect } from "react";
import { employeeIssues as empissues } from "../../lib/label.js";
import { ROUTES } from "../../utils/constants/routes";
import Container from "../ReusableComponents/Container.js";
import CreateIssueForm from "./CreateIssueForm";
import EmployeeIssueList from "./EmployeeIssueList";
import useUserRole from "../../hooks/useUserRole";
import { GlobalContext } from "../../utils/context/Global/GlobalContext.js";
import { ACTION_BUTTONS, PAGINATION } from "../../utils/constants/keywords";
import { SWITCH_ICON } from "../../utils/Components/imageUrls.js";

const MyIssues = () => {
  const [isAddingIssue, setIsAddingIssue] = useState(false);
  const [newIssuesButtonActive, setNewIssuesButtonActive] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [status, setStatus] = useState("");
  const userRole = useUserRole();

  const { issues, totalIssues, fetchIssues } = useContext(GlobalContext);

  const [issuesPerPage] = useState(PAGINATION.ISSUE_PER_PAGE);

  const totalPages = Math.ceil((totalIssues || 0) / issuesPerPage);

  useEffect(() => {
    fetchIssues(pageNo, status);
  }, [fetchIssues, pageNo, status]);

  useEffect(() => {}, [issues, totalIssues]);

  const handleNewIssueClick = () => {
    setIsAddingIssue(true);
    setNewIssuesButtonActive(false);
  };

  const handleCancel = () => {
    setIsAddingIssue(false);
    setNewIssuesButtonActive(true);
  };

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setPageNo(pageNo <= totalPages ? pageNo : totalPages);
  };

  const roleToRouteMap = {
    HR: ROUTES.HR_ISSUES,
    "Senior Management": ROUTES.SENIOR_MANAGEMENT_ISSUES,
    "IT Support": ROUTES.HR_ISSUES,
    Marketing: ROUTES.HR_ISSUES,
    Admin: ROUTES.HR_ISSUES,
    Sales: ROUTES.HR_ISSUES,
    Finance: ROUTES.HR_ISSUES,
    DEG: ROUTES.HR_ISSUES,
    TAG: ROUTES.HR_ISSUES,
  };

  const renderRoleButton = (role) => (
    <a href={roleToRouteMap[role]}>
      <button
        type="button"
        className="rounded-sm border border-blue-600 bg-white p-3 text-sm font-semibold text-blue-600 shadow-sm flex items-center"
      >
        <div className="px-4">{role}</div>
        <img
          loading="lazy"
          src={SWITCH_ICON}
          className="shrink-0 aspect-[1.06] w-[17px]"
        />
      </button>
    </a>
  );

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <div className="m-2 px-5 w-full flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">{empissues.empIssues}</span>
            <span className="text-slate-500">{empissues.myIssuesSubTitle}</span>
          </div>
          <div className="flex gap-4">
            {userRole in roleToRouteMap && renderRoleButton(userRole)}
            <button
              type="button"
              className={`rounded-sm p-3 text-sm font-semibold text-white shadow-sm ${
                newIssuesButtonActive
                  ? "bg-blue-700"
                  : "bg-blue-400 cursor-not-allowed"
              }`}
              onClick={handleNewIssueClick}
              disabled={!newIssuesButtonActive}
            >
              {ACTION_BUTTONS.NEW_ISSUES}
            </button>
          </div>
        </div>
        {isAddingIssue && (
          <CreateIssueForm onCancel={handleCancel} onSubmit={handleCancel} />
        )}
        <EmployeeIssueList
          issues={issues}
          currentPage={pageNo}
          onPageChange={handlePageChange}
          currentStatus={status}
          onStatusChange={handleStatusChange}
          totalIssues={totalIssues}
          totalPages={totalPages}
        />
      </Container>
    </div>
  );
};

export default MyIssues;
