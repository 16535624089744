import React from "react";
import EmployeeViewField from "../../EmployeeProfileManager/EmployeeViewField";
import { viewPageInputs } from "../../EmployeeProfileManager/viewPageInputs";

const EmployeeDetailSection = ({ employee, sectionTypes }) => (
  <div className="flex flex-col flex-1">
    {sectionTypes?.map(sectionType => (
      <div key={viewPageInputs[sectionType]?.id} className="flex flex-col ">
        <div className="flex flex-col px-5  sm:px-10   text-black">
          <h2 className="text-lg font-bold leading-7 text-gray-900">
            {viewPageInputs[sectionType]?.heading?.label}
          </h2>
          <p className="mt-1 text-base font-normal leading-6 text-gray-600">
            {viewPageInputs[sectionType]?.heading?.field}
          </p>
        </div>
        <div className="px-5 sm:pl-10">
          {(Array.isArray(employee?.[sectionType]) && (
            <div className="border-b mb-8">
              {((employee?.[sectionType]?.length > 0 && employee?.[sectionType]) || [{}]).map(
                emp => (
                  <EmployeeViewField
                    key={viewPageInputs[sectionType]?.id}
                    field={viewPageInputs[sectionType]?.fields}
                    employee={emp}
                  />
                )
              )}
            </div>
          )) || (
            <EmployeeViewField
              key={viewPageInputs[sectionType]?.id}
              field={viewPageInputs[sectionType]?.fields}
              employee={employee}
            />
          )}
        </div>
      </div>
    ))}
  </div>
);
export default React.memo(EmployeeDetailSection);
