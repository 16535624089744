import React, { useMemo } from 'react';
import { ERROR_MESSAGES } from '../../utils/constants/messages';

function nameLogo(name) {
  if (!name) return '';
  const firstLetter = name[0].toUpperCase();
  const secondLetter = (name.includes(' ') && name[name.indexOf(' ') + 1]?.toUpperCase()) || '';
  return firstLetter + secondLetter;
}

const DisplayNamePic = ({ employeeName }) => {
  const displayNamePic = useMemo(() => nameLogo(employeeName), [employeeName]);
  return <>{displayNamePic}</>;
};

const Icon = ({ empPhoto, empName,height = 'h-16',width = 'w-16' }) =>
  (empPhoto && empPhoto.length > 0 && (
    <img
      loading="lazy"
      src={empPhoto}
      alt={ERROR_MESSAGES.profilePhotoAlt}
      className={`${height} ${width}  rounded-full object-cover object-center text-xs bg-imgBg`}
    />
  )) || (
    <div className={`${height} ${width} font-bold text-lg bg-imgBg min-w-min min-h-min text-imgText rounded-full  shadow-sm border flex justify-center items-center`}>
      <DisplayNamePic employeeName={empName} />
    </div>
  );

export default React.memo(Icon);
