import React, { useRef } from "react";
import { editPageInputs } from "./editPageInputs";
import { ACTION_BUTTONS, API_RESPONSE_STATUS, FIELD_LABELS, INPUT_TYPES } from "../../utils/constants/keywords";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { postFile } from "../../utils/axios/apiServices";
import { USER } from "../../lib/api";
import { ERROR_MESSAGES } from "../../utils/constants/messages";
import { toast } from "react-toastify";
import Button from "../ReusableComponents/Button";

const ProfilePhoto = ({ photoData, handleChange, setProfilePhotoId, removePhoto }) => {
  const fileInputRef = useRef(null);
  const handleImageUpload = async event => {
    const file = event.target.files;
    if (file && file.length > 0) {
      try {
        const response = await postFile(USER.IMAGE_UPLOAD, file[0], INPUT_TYPES.IMAGE);

        if (response?.status == API_RESPONSE_STATUS.SUCCESS && handleChange) {
          if (setProfilePhotoId) {
            setProfilePhotoId(photoData?.profileId);
          }
          handleChange({
            target: {
              name: editPageInputs.profilePhoto,
              value: {
                profileId: response?.data?._id,
                url: response?.data?.url,
              },
            },
          });
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.profilePhotoUpload);
      }
    }
  };
  const isProfileImgButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="mt-10 grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-6">
      <div className="col-span-full">
        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
          {FIELD_LABELS.PHOTO}
          {/* <span className="text-red-500"> *</span> */}
        </label>
        <div className="mt-2 flex items-center gap-x-3">
          {(photoData?.url && photoData?.url?.length > 0 && (
            <img src={photoData?.url} className="h-12 w-12 rounded-full object-cover" alt="" />
          )) || <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />}
          <button
            type="button"
            onClick={isProfileImgButtonClick}
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {ACTION_BUTTONS.CHANGE}
          </button>
          {photoData?.url && photoData?.url?.length > 0 && (
            <Button children={ACTION_BUTTONS.REMOVE} variant={ACTION_BUTTONS.REMOVE} onClick={removePhoto} />
          )}
          <input
            className="hidden"
            ref={fileInputRef}
            name={editPageInputs.profilePhoto}
            type={INPUT_TYPES.FILE}
            onChange={handleImageUpload}
            accept="image/jpeg , image/png , image/png"
            required={false}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfilePhoto);
