import React from "react";
import { Link } from "react-router-dom";

const DepartmentCard = ({
  dept,
  departmentCounts,
  ROUTES,
  ALL_DEPT_CARD_ICON,
  SENIOR_MANAGEMENT_PAGE_LABELS,
}) => (
  <Link
    key={dept.role}
    to={`${ROUTES.DEPARTMENT_ISSUES}?role=${dept.role}`}
    className="flex gap-4 py-5 pr-20 pl-5 bg-white rounded max-md:pr-5"
  >
    <div className="flex justify-center items-center self-start p-2.5 w-10 h-10 bg-blue-700 rounded">
      <img
        loading="lazy"
        src={ALL_DEPT_CARD_ICON}
        className="w-6 aspect-square"
        alt={`${dept.name} icon`}
      />
    </div>
    <div className="flex flex-col">
      <div className="text-xs font-medium text-gray-500">{dept.name}</div>
      <div className="mt-2 text-base font-semibold text-zinc-400">
        <span className="text-gray-500 mr-1">
          {SENIOR_MANAGEMENT_PAGE_LABELS.TOTAL_ISSUES}
        </span>
        <span className="text-blue-700">
          {departmentCounts[dept.role] || 0}
        </span>
      </div>
    </div>
  </Link>
);

const DepartmentCards = ({
  departments,
  departmentCounts,
  ROUTES,
  ALL_DEPT_CARD_ICON,
  SENIOR_MANAGEMENT_PAGE_LABELS,
}) => {
  const renderDepartmentGroup = (depts) => (
    <div className="flex gap-5 justify-between px-5 max-md:flex-wrap">
      {depts.map((dept) => (
        <DepartmentCard
          key={dept.role}
          dept={dept}
          departmentCounts={departmentCounts}
          ROUTES={ROUTES}
          ALL_DEPT_CARD_ICON={ALL_DEPT_CARD_ICON}
          SENIOR_MANAGEMENT_PAGE_LABELS={SENIOR_MANAGEMENT_PAGE_LABELS}
        />
      ))}
    </div>
  );

  return (
    <div className="flex flex-col mb-5">
      {renderDepartmentGroup(departments.slice(0, 4))}
      <div className="mt-5">{renderDepartmentGroup(departments.slice(4))}</div>
    </div>
  );
};

export default DepartmentCards;
