// utils/constants/departments.js

export const DEPARTMENTS = [
  { name: "HR Department", role: "HR" },
  { name: "DEG Department", role: "DEG" },
  { name: "Finance Department", role: "Finance" },
  { name: "Admin Department", role: "Admin" },
  { name: "IT Department", role: "ITSupport" },
  { name: "Sales Department", role: "Sales" },
  { name: "Marketing Department", role: "Marketing" },
  { name: "TAG Department", role: "TAG" },
];

export const getDepartmentCode = (role) => {
  const department = DEPARTMENTS.find((dept) => dept.role === role);
  return department ? department.role : role;
};

export const getDepartmentName = (role) => {
  const department = DEPARTMENTS.find((dept) => dept.role === role);
  return department ? department.name : role;
};
