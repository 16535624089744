import React from 'react';

export default function CheckBox({ filterList, handleSelectionFilter, selectedFilters }) {

  // Handle change function to update state

  const handleChange = (e, filterType) => {
    if (e.target.checked) {
      handleSelectionFilter([...selectedFilters, filterType]);
    } else {
      handleSelectionFilter(selectedFilters.filter(filter => filter.id !== filterType.id));
    }
  };

  return (
    <fieldset className="absolute z-50 bg-white mt-4 rounded-xl shadow-2xl opacity-0 transform  transition-opacity duration-700 ease-in-out pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
      <div className="rounded-xl shadow-2xl">
        <div className="divide-y divide-gray-200 border-gray-200">
          {filterList?.map(filterType => (
            <div key={filterType.id} className="relative flex items-start py-2 px-2">
              <div className="min-w-0 flex-1 text-xs leading-6">
                <label
                  htmlFor={filterType.id}
                  className="select-none text-gray-600 whitespace-nowrap">
                  {filterType.name}
                </label>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id={filterType.id}
                  name={filterType.id}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={selectedFilters?.some(filter => filter.id === filterType.id)}
                  onChange={e => handleChange(e, filterType)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
}
