// src/utils/imageUrls.js
export const SUBMIT_COMMENT_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/001f67561e3913e8d03a8567384dab501415fa7f4de452cfd5de5fca8b8317cd?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&&apiKey=d8e91bdc04594ed59f135bc4e59e9ebd";
export const DOWNLOAD_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/4c92b2ef6d4bb6ab9e44734db06cfd948838761b438997a9f921b7b5b0d55bae?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";
export const DEFAULT_USER_IMAGE = "https://via.placeholder.com/150";

export const FILTER_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/9444c345f3026d5fa9fa88f6aa84777aa2632e9533acf0572189b3d4d9504d06?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";

export const EDIT_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/6660a39e7a23682f41b1f91511125d9df78abccb46eec81d8ca03afd34d07856?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";

export const CANCEL_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/125acc98a99ec6f16857b02dd84dea5101a45bdeec962f67651e4b1427c8ea5a?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";

export const CARD_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/1747200ac8624f19569a1450b8166d78eedea19aca7ce712c960dd2527d06b65?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";

export const SWITCH_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/80efa7175ba5cb97e482682ea3e465b0dd209817143ce5345ba087de1e6701c5?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&";

export const ALL_DEPT_CARD_ICON =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/4dc4bcd8b94a09340f18c91534a6b505071c2a5a9432b6f7034828e87f2f53aa?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&&apiKey=d8e91bdc04594ed59f135bc4e59e9ebd";
