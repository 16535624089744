import React, { useEffect, useState } from "react";
import { ROUTES } from "../../utils/constants/routes";
import BackNavigation from "../ReusableComponents/BackNavigation";
import Container from "../ReusableComponents/Container";
import { AllDepartment as allissues } from "../../lib/label";
import { ALL_DEPT_CARD_ICON } from "../../utils/Components/imageUrls";
import { getHelper } from "../../utils/axios/apiServices";
import { ISSUES } from "../../lib/api";
import DepartmentCards from "../../Components/SeniorManagementIssues/DepartmentCards";
import { ERROR_MESSAGES } from "../../utils/constants/messages";
import { toast } from "react-toastify";
import {
  SENIOR_MANAGEMENT_PAGE_LABELS,
  TABLE_HEADING,
} from "../../utils/constants/keywords";
import {
  PRIORITY_CLASSES,
  PRIORITY_OPTIONS,
  STATUS_OPTIONS,
  DURATION_OPTIONS,
  REASSIGN_OPTIONS,
} from "../../utils/constants/dropdown";
import { getDurationText } from "../../utils/Components/dateUtils";
import AllIssueTable from "./AllIssueTable";
import { DEPARTMENTS } from "../../utils/constants/departments";

const SeniorManagementIssues = () => {
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [departmentCounts, setDepartmentCounts] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    priority: {},
    status: {},
    duration: {},
    assigned: {},
  });
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchIssues();
    fetchDepartmentCounts();
  }, [pageNo]);

  useEffect(() => {
    filterIssues();
  }, [selectedFilters, issues]);

  const fetchDepartmentCounts = async () => {
    try {
      const response = await getHelper(
        `${ISSUES.SENIOR_DEPT_ISSUE_COUNTS}`,
        () => {},
        {}
      );

      if (!response || !response.success) {
        toast.error(ERROR_MESSAGES.INVALID_DATA_ERROR);
        return;
      }

      const countsObject = response.data.reduce((acc, item) => {
        acc[item.department] = item.count;
        return acc;
      }, {});

      setDepartmentCounts(countsObject);
    } catch (error) {
      toast.error(ERROR_MESSAGES.FETCH_ERROR);
    }
  };

  const fetchIssues = async () => {
    setIsLoading(true);

    try {
      const data = await getHelper(
        `${ISSUES.PAGINATION}?page=${pageNo}`,
        () => {},
        {}
      );

      if (!data || !data.data) {
        toast.error(ERROR_MESSAGES.INVALID_DATA_ERROR);
        return;
      }

      setIssues(data.data);
      setTotalPages(data.pagination?.totalPages ?? 1);
    } catch (error) {
      toast.error(ERROR_MESSAGES.FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const filterIssues = () => {
    let filtered = issues;
    const { priority, status, duration, assigned } = selectedFilters;

    const applyFilter = (issueProperty, filterObject) => {
      const selectedOptions = Object.keys(filterObject).filter(
        (option) => filterObject[option]
      );
      return selectedOptions.length > 0
        ? filtered.filter((issue) =>
            selectedOptions.includes(issueProperty(issue))
          )
        : filtered;
    };

    filtered = applyFilter((issue) => issue.priority, priority);
    filtered = applyFilter((issue) => issue.status, status);
    filtered = applyFilter(
      (issue) => getDurationText(issue.createdAt),
      duration
    );
    filtered = applyFilter((issue) => issue.assignedTo.department, assigned);

    setFilteredIssues(filtered);
  };

  const handleFilterChange = (type, option) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [option]: !prev[type][option],
      },
    }));
    setPageNo(1);
  };

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  return (
    <div className="flex flex-col pb-6 bg-gray-100">
      <Container>
        <div className="flex flex-col px-20 mt-5 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex items-center mb-6">
            <BackNavigation className="h-5 w-5 text-gray-500" />
            <span className="ml-2 mt-5 text-gray-500">Back</span>
          </div>

          <DepartmentCards
            departments={DEPARTMENTS}
            departmentCounts={departmentCounts}
            ROUTES={ROUTES}
            ALL_DEPT_CARD_ICON={ALL_DEPT_CARD_ICON}
            SENIOR_MANAGEMENT_PAGE_LABELS={SENIOR_MANAGEMENT_PAGE_LABELS}
          />

          <div className="ml-4 mb-6">
            <h1 className="text-xl font-semibold text-gray-900">
              {allissues.DepartmentIssues}
            </h1>
            <p className="text-sm text-gray-600">
              {allissues.DepartmentSubTitle}
            </p>
          </div>

          <div className="bg-white shadow-md rounded">
            <AllIssueTable
              filteredIssues={filteredIssues}
              selectedFilters={selectedFilters}
              REASSIGN_OPTIONS={REASSIGN_OPTIONS}
              PRIORITY_OPTIONS={PRIORITY_OPTIONS}
              STATUS_OPTIONS={STATUS_OPTIONS}
              DURATION_OPTIONS={DURATION_OPTIONS}
              handleFilterChange={handleFilterChange}
              isLoading={isLoading}
              totalPages={totalPages}
              pageNo={pageNo}
              handlePageChange={handlePageChange}
              PRIORITY_CLASSES={PRIORITY_CLASSES}
              TABLE_HEADING={TABLE_HEADING}
              SENIOR_MANAGEMENT_PAGE_LABELS={SENIOR_MANAGEMENT_PAGE_LABELS}
              ROUTES={ROUTES}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SeniorManagementIssues;
