import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropdown from "../ReusableComponents/Dropdown";
import {
  PRIORITY_OPTIONS,
  DEPARTMENT_OPTIONS,
} from "../../utils/constants/dropdown";
import { postHelper } from "../../utils/axios/apiServices";
import { toast } from "react-toastify";
import { ISSUES } from "../../lib/api";

import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages";
import {
  FIELD_LABELS,
  STATUS,
  ACTION_BUTTONS,
} from "../../utils/constants/keywords";

const CreateIssueForm = ({ onCancel, onSubmit }) => {
  const [showAssignDropdown, setShowAssignDropdown] = useState(false);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      id: `${file.name}-${file.lastModified}`,
      file,
    }));
    const existingFileNames = files.map((fileObj) => fileObj.file.name);

    const validFormats = [
      "image/jpeg",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
    ];
    let hasDuplicate = false;
    let hasInvalidFormat = false;

    newFiles.forEach((newFileObj) => {
      if (existingFileNames.includes(newFileObj.file.name)) {
        hasDuplicate = true;
      }

      if (!validFormats.includes(newFileObj.file.type)) {
        hasInvalidFormat = true;
      }
    });

    if (hasDuplicate) {
      toast.error(FIELD_LABELS.DUPLICATE_FILE);
    }
    if (hasInvalidFormat) {
      toast.error(FIELD_LABELS.INVALID_FILE_FORMAT);
    }

    if (!hasDuplicate && !hasInvalidFormat) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }

    event.target.value = "";
  };

  const handleFileRemove = (fileToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((fileObj) => fileObj.id !== fileToRemove.id)
    );
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      issueDescription: "",
      priority: "",
      status: STATUS.OPEN,
      assignedTo: {
        department: "",
      },
    },
    validationSchema: Yup.object({
      subject: Yup.string()
        .max(50, ERROR_MESSAGES.SUBJECT_MAX_LENGTH)
        .required(ERROR_MESSAGES.SUBJECT_REQUIRED),
      issueDescription: Yup.string()
        .max(200, ERROR_MESSAGES.DESCRIPTION_MAX_LENGTH)
        .required(ERROR_MESSAGES.DESCRIPTION_REQUIRED),
      priority: Yup.string()
        .oneOf(PRIORITY_OPTIONS, ERROR_MESSAGES.PRIORITY_INVALID)
        .required(ERROR_MESSAGES.PRIORITY_REQUIRED),
      assignedTo: Yup.object({
        department: Yup.string().required(ERROR_MESSAGES.SELECT_DEPARTMENT),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        files.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });
        formData.append("subject", values.subject);
        formData.append("issueDescription", values.issueDescription);
        formData.append("priority", values.priority);
        formData.append("status", values.status);
        formData.append("assignedTo.department", values.assignedTo.department);

        const response = await postHelper(
          ISSUES.CREATE_ISSUE,
          formData,
          SUCCESS_MESSAGES.ISSUE_CREATED,
          ERROR_MESSAGES.ISSUE_CREATION_FAILED
        );

        if (!response?.data?.documentUrl) return;

        resetForm();
        setFiles([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        onSubmit();
      } catch (error) {
        toast.error(ERROR_MESSAGES.ISSUE_CREATION_FAILED);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="px-5 mt-6 w-full max-md:max-w-full"
    >
      <div className="relative w-full p-4 bg-white mt-4 rounded-md shadow-md">
        <p className="my-5 font-bold">Create Issues</p>
        <FormField
          label={FIELD_LABELS.SUBJECT}
          name="subject"
          placeholder="Subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.subject && formik.errors.subject}
        />
        <FormTextArea
          label={FIELD_LABELS.ISSUE_DESCRIPTION}
          name="issueDescription"
          placeholder="Write a description..."
          value={formik.values.issueDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.issueDescription && formik.errors.issueDescription
          }
        />
        <div className="flex justify-between items-start mt-4">
          <div className="w-[70%]">
            <Attachments files={files} onFileRemove={handleFileRemove} />
          </div>
          <AttachFilesButton
            onFileChange={handleFileChange}
            fileInputRef={fileInputRef}
          />
        </div>
        <div className="flex justify-between items-end mt-4">
          <div className="flex gap-4">
            <DropdownField
              label={FIELD_LABELS.ASSIGN}
              showDropdown={showAssignDropdown}
              setShowDropdown={setShowAssignDropdown}
              options={DEPARTMENT_OPTIONS.map((option) => ({
                id: option,
                name: option,
              }))}
              selectedOption={formik.values.assignedTo.department}
              onSelect={(option) => {
                formik.setFieldValue("assignedTo.department", option);
                setShowAssignDropdown(false);
              }}
              error={
                formik.touched.assignedTo?.department &&
                formik.errors.assignedTo?.department
              }
            />
            <DropdownField
              label={FIELD_LABELS.PRIORITY}
              showDropdown={showPriorityDropdown}
              setShowDropdown={setShowPriorityDropdown}
              options={PRIORITY_OPTIONS.map((option) => ({
                id: option,
                name: option,
              }))}
              selectedOption={formik.values.priority}
              onSelect={(option) => {
                formik.setFieldValue("priority", option);
                setShowPriorityDropdown(false);
              }}
              error={formik.touched.priority && formik.errors.priority}
            />
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              className="px-4 py-2 bg-white text-black border font-medium rounded-md"
              onClick={onCancel}
            >
              {ACTION_BUTTONS.CANCEL}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-700 font-medium text-white rounded-md"
            >
              {ACTION_BUTTONS.SUBMIT}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const FormField = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => (
  <div className="mb-4">
    <input
      type="text"
      name={name}
      placeholder={placeholder}
      className="w-full p-2 border rounded-md"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
    {error && <div className="text-red-600 text-sm p-3">{error}</div>}
  </div>
);

const FormTextArea = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => (
  <div className="relative w-full mb-4 border rounded-md">
    <textarea
      name={name}
      placeholder={placeholder}
      className="w-full h-40 p-2 border-gray-300 focus:outline-none focus:border-transparent"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
    {error && <div className="text-red-600 text-sm p-3">{error}</div>}
  </div>
);

const AttachFilesButton = ({ onFileChange, fileInputRef }) => (
  <label className="flex items-center cursor-pointer px-2 py-1 rounded-md text-black-700">
    {FIELD_LABELS.ATTACH_FILE}
    <input
      type="file"
      className="hidden"
      onChange={onFileChange}
      ref={fileInputRef}
      multiple
    />
  </label>
);

const Attachments = ({ files, onFileRemove }) => (
  <div className="mt-2 p-2 min-h-[40px]">
    <div className="flex flex-wrap">
      {files.map((fileObj) => (
        <div
          key={fileObj.id}
          className="flex items-center mr-2 mb-2 bg-gray-100 p-1 rounded-md"
        >
          <span className="mr-2">{fileObj.file.name}</span>
          <button
            type="button"
            className="text-red-600"
            onClick={() => onFileRemove(fileObj)}
          >
            {FIELD_LABELS.CROSS_SYMBOL}
          </button>
        </div>
      ))}
    </div>
  </div>
);

const DropdownField = ({
  label,
  showDropdown,
  setShowDropdown,
  options,
  selectedOption,
  onSelect,
  error,
}) => (
  <div className="relative flex gap-4 text-sm font-semibold leading-5 text-center text-gray-500">
    <button
      type="button"
      className="relative flex flex-col justify-center px-3.5 py-3.5 rounded-full bg-zinc-100"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <div className="flex gap-0.5">{selectedOption || label}</div>
      {showDropdown && <Dropdown options={options} onSelect={onSelect} />}
    </button>
    {error && <div className="text-red-600 text-sm">{error}</div>}
  </div>
);

export default CreateIssueForm;
