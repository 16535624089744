import React from "react";
import SectionHeader from "./SectionHeader";
import TextInputField from "../ReusableComponents/TextInputField";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ACTION_BUTTONS, INPUT_TYPES } from "../../utils/constants/keywords";

const EmployeeArrayInputFields = ({
  inputs,
  formik,
  handleNestedChange,
  heading,
  description,
  addSection,
  handleAddDetail,
  hideAdd,
  isFormSubmit = true,
  handleSubDetail,
}) => (
  <>
    <div className="flex justify-between">
      <SectionHeader heading={heading} description={description} />
      {addSection.length < 4 && (
        <div
          hidden={hideAdd}
          className="text-base text-addButton font-normal cursor-pointer justify-end "
          onClick={handleAddDetail}
        >
          {ACTION_BUTTONS.ADD}
        </div>
      )}
    </div>
    <div>
      {addSection?.map((detailItems) => (
        <div key={detailItems.id} className="group">
          <div className="flex justify-end my-6  relative">
            {addSection.length > 1 && (
              <XMarkIcon
                data-testid="xmark-icon"
                className="h-5 w-5 border-2 border-gray-400 text-gray-400 rounded-full invisible group-hover:visible cursor-pointer"
                onClick={() => handleSubDetail(detailItems.id)}
              />
            )}
          </div>
          <div
            className={` ${
              inputs.length !== 1
                ? "grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
                : null
            }`}
          >
            {inputs?.map((input) => (
              <TextInputField
                key={input.id}
                input={input}
                value={
                  (INPUT_TYPES.FILE !== input.type && formik.values[input.array]?.[detailItems.detail]?.[input.name]) ||
                  ""
                }
                handleChange={(e) =>
                  handleNestedChange(
                    input.array,
                    detailItems.detail,
                    input.name,
                    (INPUT_TYPES.FILE !== input.type && e.target.value) || e.target.files,
                    INPUT_TYPES.FILE === input.type && INPUT_TYPES.FILE
                  )
                }
                touched={true}
                errors={formik.errors[input.array]?.[detailItems.detail]?.[input.name]}
                isFormSubmit={isFormSubmit}
                fileName={
                  INPUT_TYPES.FILE === input.type &&
                  formik.values[input.array]?.[detailItems.detail]?.[input.name]?.fileName
                }
                isDisable={
                  (input.disableValue &&
                    input.name !== input.disableValue.field &&
                    (input.objectName
                      ? !formik.values[input.disableValue.obj]?.[input.disableValue.field]
                      : !formik.values[input.disableValue.field])) ||
                  false
                }
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  </>
);

export default React.memo(EmployeeArrayInputFields);