import React, { useEffect, useState } from "react";
import { USER } from "../lib/api";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  editPageInitialValues,
  editPageInputs,
  editPageValidationSchema,
} from "../Components/CreateEmployee/editPageInputs";
import {
  ACTION_BUTTONS,
  API_FIELD_NAMES,
  DEFAULT_SELECT,
  FIELD_HEADING,
  INPUT_TYPES,
  roles,
} from "../utils/constants/keywords";
import { viewPageInputs } from "../Components/EmployeeProfileManager/viewPageInputs";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../utils/constants/messages";
import { deleteFile, editHelper, getHelper, postFile } from "../utils/axios/apiServices";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import EditableUserDetailSection from "../Components/EmployeeProfileManager/EditableUserDetailSection";
import EmployeeHeader from "../Components/ReusableComponents/EmployeeHeader";
import LocalStorage from "../utils/StorsgeUtil/LocalStorage";
import EditableUserSkillDetailSection from "../Components/EmployeeProfileManager/EditableUserSkillDetailSection";
import { myDataVerticalNavigation, ROUTES } from "../utils/constants/routes";
import Button from "../Components/ReusableComponents/Button";
import VerticalNavigation from "../Components/ReusableComponents/VerticalNavigation";

const EmployeeProfileManager = () => {
  const navigate = useNavigate();
  const submitButtonStatus = [
    { id: 1, isVisible: false, name: API_FIELD_NAMES.PROFILE },
    { id: 2, isVisible: false, name: API_FIELD_NAMES.PERSONAL },
    { id: 3, isVisible: false, name: API_FIELD_NAMES.EDUCATION_OBJ },
    { id: 4, isVisible: false, name: API_FIELD_NAMES.CERTIFICATION_OBJ },
    { id: 5, isVisible: false, name: API_FIELD_NAMES.SKILL_SET },
    { id: 6, isVisible: false, name: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ },
    { id: 7, isVisible: false, name: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ },
    { id: 8, isVisible: false, name: API_FIELD_NAMES.MY_TRAINING },
    { id: 9, isVisible: false, name: API_FIELD_NAMES.BANK_DETAILS },
    { id: 10, isVisible: false },
    { id: 11, isVisible: false },
    { id: 12, isVisible: false, name: API_FIELD_NAMES.FAMILY_DETAILS_OBJ },
    { id: 13, isVisible: false },
    { id: 14, isVisible: false },
  ];
  // editSelection
  const [editSelection, setEditSelection] = useState(submitButtonStatus);
  const { empId } = useParams();
  const userData = JSON.parse(LocalStorage.getUserData());
  const role = userData?.role;
  const hideEdit = role !== roles.HR;
  const [profilePhotoId, setProfilePhotoId] = useState("");
  const [educationalDocumentId, setEducationalDocumentId] = useState("");
  const [employmentDocumentId, setEmploymentDocumentId] = useState("");
  const [existData, setExistData] = useState([]);

  const handleBack = () => {
    navigate((role === roles.HR && ROUTES.EMPLOYEES) || -1);
  };

  const formik = useFormik({
    initialValues: editPageInitialValues,
    validationSchema:
      editPageValidationSchema[editSelection?.filter((elements) => elements.isVisible === true)?.[0]?.name],
    onSubmit: async (values) => {
      const modifiedValues = { ...values };
      // Only delete unchanged fields if form is being submitted
      Object.keys(values).forEach((element) => {
        if (existData[element] === values[element]) {
          delete modifiedValues[element];
        }
      });

      // Proceed with submitting only if there are changes
      if (Object.keys(modifiedValues).length > 0) {
        const editURL = `${USER.EDIT_BY_ID}/${empId}`;
        try {
          const response = await editHelper(editURL, modifiedValues, SUCCESS_MESSAGES.PROFILE_UPDATE);
          if (response?.success) {
            setEditSelection(submitButtonStatus);
            formik.setValues({
              ...values,
              ...response,
            });
            if (profilePhotoId.length > 0 && formik.values.profilePhoto?.profileId !== profilePhotoId) {
              deleteFile(`${USER.DELETE_IMAGE}/${profilePhotoId}`);
            }
            if (
              educationalDocumentId.length > 0 &&
              formik.values.educationalDocuments?.documentId !== educationalDocumentId
            ) {
              deleteFile(`${USER.EDUCATION_DOC_UPLOAD}/${educationalDocumentId}`);
            }
            if (
              employmentDocumentId.length > 0 &&
              formik.values.employmentDocuments?.documentId !== employmentDocumentId
            ) {
              deleteFile(`${USER.EMPLOYMENT_DOC_UPLOAD}/${employmentDocumentId}`);
            }
          } else {
            toast.error(
              String(response?.message).charAt(0).toUpperCase() + String(response?.message).slice(1).toLowerCase()
            );
          }
        } catch (error) {
          // Handle error if needed
        }
      } else {
        setEditSelection(submitButtonStatus);
      }
    },
  });

  const removePhoto = () => {
    formik.setFieldValue(API_FIELD_NAMES.PROFILE_PHOTO, null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const getURL = `${USER.GET_BY_ID}/${empId}`;
      const response = await getHelper(getURL, setExistData);
      formik.setValues({ ...editPageInitialValues, ...response?.data });
      setEducationalDocumentId(formik.values?.educationalDocuments?.documentId);
      setEmploymentDocumentId(formik.values?.employmentDocuments?.documentId);
    };
    fetchData();
  }, [editSelection, empId]);

  const addEmptyFields = (isEditMode) => {
    const arraySection = [
      {
        sectionName: FIELD_HEADING.CERTIFICATION_DETAILS,
        sectionApiName: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
      {
        sectionName: FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS,
        sectionApiName: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        sectionName: FIELD_HEADING.EDUCATION_DETAILS,
        sectionApiName: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        sectionName: FIELD_HEADING.FAMILY_DETAILS,
        sectionApiName: API_FIELD_NAMES.FAMILY_DETAILS_OBJ,
      },
      {
        sectionName: FIELD_HEADING.MY_TRAINING,
        sectionApiName: API_FIELD_NAMES.MY_TRAINING,
      },
    ];

    arraySection.forEach(({ sectionApiName, sectionName }) => {
      if (
        formik.values?.[sectionApiName]?.length === 0 &&
        (isEditMode || sectionApiName !== API_FIELD_NAMES.MY_TRAINING)
      ) {
        handleAddDetail(sectionName);
      }
    });
  };

  useEffect(() => {
    addEmptyFields(true);
  }, [formik.values]);

  // Adding Nested data values in formik
  const handleNestedChange = async(array, index, name, value, type) => {
    let data = value;
    if (type === INPUT_TYPES.FILE) {
      const dataFile = value;
      if (dataFile && dataFile.length > 0) {
        try {
          const response = await postFile(USER.EDUCATION_DOC_UPLOAD, dataFile[0], INPUT_TYPES.FILE);

          data = {
            documentId: response?.document?._id,
            url: response?.document?.url,
          };
        } catch (error) {
          toast.error(ERROR_MESSAGES.documentUpload);
        }
      }
    }
    formik.setFieldValue(`${array}[${index}][${name}]`, data);
  };

  const handleFormCancel = () => {
    setEditSelection(submitButtonStatus);
  };

  // Switching to edit mode
  const handleEdit = (index) => {
    setEditSelection(submitButtonStatus);
    setEditSelection((prevSelection) =>
      prevSelection.map((item) =>
        item.id === index + 1 ? { ...item, isVisible: !editSelection[index].isVisible } : item
      )
    );
  };

  // Adding the new empty Object in exisiting Employee List
  const handleAddDetail = (heading) => {
    const detailMappings = {
      [FIELD_HEADING.EDUCATION_DETAILS]: {
        property: API_FIELD_NAMES.EDUCATION_OBJ,
        newDetail: {
          level: DEFAULT_SELECT,
          university: "",
          specialization: "",
          startDate: "",
          endDate: "",
          percentage: "",
        },
      },
      [FIELD_HEADING.CERTIFICATION_DETAILS]: {
        property: API_FIELD_NAMES.CERTIFICATION_OBJ,
        newDetail: {
          certificationName: "",
          institute: "",
          certificationYear: "",
        },
      },
      [FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS]: {
        property: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
        newDetail: {
          designation: "",
        },
      },
      [FIELD_HEADING.MY_TRAINING]: {
        property: API_FIELD_NAMES.MY_TRAINING,
        newDetail: {},
      },
      [FIELD_HEADING.FAMILY_DETAILS]: {
        property: API_FIELD_NAMES.FAMILY_DETAILS_OBJ,
        newDetail: {},
      },
    };

    // Retrieve the property and newDetail from the mappings
    const { property, newDetail } = detailMappings[heading];

    // Update the state based on the property
    formik.setValues((prevData) => ({
      ...prevData,
      [property]: [...(prevData[property] || []), newDetail],
    }));
  };
  const handleUpdateSkill = (skillType, updatedSkills) => {
    formik.setValues({
      ...formik.values,
      skillSet: {
        ...formik.values.skillSet,
        [skillType]: updatedSkills,
      },
    });
  };

  //Details Remove Button
  const handleSubDetail = (type, selectionDetail) => {
    const removedDetail = formik.values[type].filter((detail) => {
      return detail !== selectionDetail;
    });
    formik.setValues((prevData) => ({ ...prevData, [type]: removedDetail }));
  };

  const handleApprove = () => {
    formik.setValues({ ...formik.values, isApproved: true });
    formik.handleSubmit();
  };

  return (
    <div className="">
      <div className="px-10  md:px-20 flex gap-x-3  mt-6 mb-6 cursor-pointer" onClick={handleBack}>
        <ArrowLeftIcon className="h-6 w-6 " />
        <div className="font-medium text-base ">Employee details</div>
      </div>
      <VerticalNavigation navigation={myDataVerticalNavigation} />
      {formik.values !== null && (
        <div className=" mx-10 md:mx-20 bg-white mb-24 ">
          <form onSubmit={formik.handleSubmit}>
            {Object.keys(viewPageInputs).map((sectionTitle) => (
              <div id="profile" key={viewPageInputs[sectionTitle].id} className=" ">
                {/* Profile Section */}
                {sectionTitle === API_FIELD_NAMES.PROFILE && (
                  <div className="group ">
                    <div className="flex justify-between mx-5 md:mx-20 items-center">
                      <EmployeeHeader
                        empDesignation={
                          formik.values?.[API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ]?.[0]?.[API_FIELD_NAMES.DESIGNATION]
                        }
                        empName={formik.values?.name}
                        empPhoto={formik.values?.profilePhoto?.url}
                      />
                    </div>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs.Profile}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      isPhotoEdit={true}
                      setProfilePhotoId={setProfilePhotoId}
                      removePhoto={removePhoto}
                    />
                    <div className="border-b "></div>
                  </div>
                )}
                {/* Personal Section */}
                {sectionTitle === API_FIELD_NAMES.PERSONAL && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs.Personal}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                    />
                    <div className="border-b "></div>
                  </>
                )}

                {/* Education Section */}
                {sectionTitle === API_FIELD_NAMES.EDUCATION_OBJ && (
                  <div id="education">
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs.Education[API_FIELD_NAMES.SPECIALIZATION_OF_STUDY]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      handleAddDetail={handleAddDetail}
                      hideEdit={hideEdit}
                      handleSubDetail={(selectionDetail) =>
                        handleSubDetail(API_FIELD_NAMES.EDUCATION_OBJ, selectionDetail)
                      }
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </div>
                )}

                {/* Certification Section */}
                {sectionTitle === API_FIELD_NAMES.CERTIFICATION_OBJ && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs.Education[API_FIELD_NAMES.CERTIFICATION_DETAILS]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      handleAddDetail={handleAddDetail}
                      handleSubDetail={(selectionDetail) =>
                        handleSubDetail(API_FIELD_NAMES.CERTIFICATION_OBJ, selectionDetail)
                      }
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}

                {/* Skillset section */}
                {sectionTitle === API_FIELD_NAMES.SKILL_SET && (
                  <>
                    <EditableUserSkillDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs.Education[API_FIELD_NAMES.SKILL_SET]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      handleUpdateSkill={handleUpdateSkill}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}
                {/* Current Employment section */}
                {sectionTitle === API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ && (
                  <div id="employment">
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL][API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      hideAdd={false}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </div>
                )}
                {/* Previous Employment section */}
                {sectionTitle === API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={
                        editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL][API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ]
                      }
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      handleAddDetail={handleAddDetail}
                      handleSubDetail={(selectionDetail) =>
                        handleSubDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ, selectionDetail)
                      }
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}
                {/* My Training Section */}
                {sectionTitle === API_FIELD_NAMES.MY_TRAINING && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.MY_TRAINING]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      handleAddDetail={handleAddDetail}
                      handleSubDetail={(selectionDetail) =>
                        handleSubDetail(API_FIELD_NAMES.MY_TRAINING, selectionDetail)
                      }
                    />
                    <div className="border-b "></div>
                  </>
                )}

                {/* Bank Details Section */}
                {sectionTitle === API_FIELD_NAMES.BANK_DETAILS && (
                  <div id="bank">
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.BANK_DETAILS]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </div>
                )}
                {/* ESI Account Details Section */}
                {sectionTitle === API_FIELD_NAMES.ESI_ACCOUNT && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.ESI_ACCOUNT]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      disable={API_FIELD_NAMES.UNDER_ESI}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}
                {/* Insurance Details Section */}
                {sectionTitle === API_FIELD_NAMES.INSURANCE_DETAILS && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.INSURANCE_DETAILS]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      disable={API_FIELD_NAMES.UNDER_INSURANCE}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}
                {/* Family Details Section */}
                {sectionTitle === API_FIELD_NAMES.FAMILY_DETAILS_OBJ && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.FAMILY_DETAILS]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      handleAddDetail={handleAddDetail}
                      handleSubDetail={(selectionDetail) =>
                        handleSubDetail(API_FIELD_NAMES.FAMILY_DETAILS_OBJ, selectionDetail)
                      }
                      disable={API_FIELD_NAMES.UNDER_INSURANCE}
                    />
                    <div className="border-b mx-5 md:mx-20"></div>
                  </>
                )}
                {/* PF Details Section */}
                {sectionTitle === API_FIELD_NAMES.PF_ACCOUNT_DETIALS && (
                  <>
                    <EditableUserDetailSection
                      sectionTitle={sectionTitle}
                      formik={formik}
                      handleNestedChange={handleNestedChange}
                      handleFormCancel={handleFormCancel}
                      inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.PF_ACCOUNT_DETIALS]}
                      editSelection={editSelection}
                      handleEdit={handleEdit}
                      hideEdit={hideEdit}
                      disable={API_FIELD_NAMES.UNDER_PF}
                    />
                    <div className="border-b "></div>
                  </>
                )}
                {/* LWF Section */}
                {sectionTitle === API_FIELD_NAMES.LABOUR_WELFARE_FUND && (
                  <EditableUserDetailSection
                    sectionTitle={sectionTitle}
                    formik={formik}
                    handleNestedChange={handleNestedChange}
                    handleFormCancel={handleFormCancel}
                    inputs={editPageInputs[API_FIELD_NAMES.LABOUR_WELFARE_FUND]}
                    editSelection={editSelection}
                    handleEdit={handleEdit}
                    hideEdit={hideEdit}
                  />
                )}
              </div>
            ))}
          </form>
          {!formik.values?.isApproved && (
            <div className="flex justify-end mr-20 pb-20 ">
              <Button variant={"save"} children={ACTION_BUTTONS.APPROVE} type={"submit"} onClick={handleApprove} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeProfileManager;
