import React from "react";
import { useLocation } from "react-router-dom";
import IssueManager from "./IssueManager";

const AllDepartmentIssues = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userRole = queryParams.get("role");

  return <IssueManager userRole={userRole} />;
};

export default AllDepartmentIssues;
