import { ArrowRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Dropdown = ({ handleTabs, tabs }) => (
  <div className="sm:hidden">
    <label htmlFor="tabs" className="sr-only">
      Select a tab
    </label>
    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
    <select
      id="tabs"
      name="tabs"
      defaultValue={tabs.find(tab => tab.current).fieldName}
      onChange={event => handleTabs(JSON.parse(event.target.value))}
      className="block w-full p-2 border rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
      {tabs.map(tab => (
        <option key={tab.id} value={JSON.stringify(tab)}>
          {tab.fieldName}
        </option>
      ))}
    </select>
  </div>
);

const ListView = ({ handleTabs, tabs }) => (
  <div className="hidden sm:block">
    <nav aria-label="Tabs" className="flex flex-col space-y-4">
      {tabs.map(tab => (
        <div
          key={tab.fieldName}
          onClick={() => handleTabs(tab)}
          aria-current={tab.current ? 'page' : undefined}
          className={classNames(
            (tab.current && 'bg-blue-50  text-blue-800  border-blue-300') ||
              'text-gray-800 hover:text-gray-900',
            ' w-56 lg:w-64 rounded-lg border p-4 text-base font-medium flex justify-between items-center cursor-pointer'
          )}>
          <span>
            {tab.id}. {tab.fieldName}
          </span>
          {tab?.current && <ArrowRightIcon className=" h-5 w-5 font-bold" />}
        </div>
      ))}
    </nav>
  </div>
);

function Tabs({ handleTabs, tabsList }) {
  return (
    <div className="px-5 pb-5 sm:pb-0 sm:pl-6 sm:px-0">
      <Dropdown handleTabs={handleTabs} tabs={tabsList} />
      <ListView handleTabs={handleTabs} tabs={tabsList} />
    </div>
  );
}

export default React.memo(Tabs);
