import { SENIOR_MANAGEMENT_ACTIONS } from '../../../utils/constants/actionTypes';

const initialSeniormanagementState = {
  filteredEmployees: [],
  paginatedEmployeeList: [],
  pageNo: 1,
  clientFilterList: [],
  departmentFilterList: [],
  taggingFilterList: [],
  statusFilterList: [],
};

const seniorManagementReducer = (state, action) => {
  switch (action.type) {
    case SENIOR_MANAGEMENT_ACTIONS.FILTERED_EMPLOYEES:
      return {
        ...state,
        filteredEmployees: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.PAGINATED_EMPLOYEES:
      return {
        ...state,
        paginatedEmployeeList: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.PAGE_NO:
      return {
        ...state,
        pageNo: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.CLIENT_FILTER_LIST:
      return {
        ...state,
        clientFilterList: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.DEPARTMENT_FILTER_LIST:
      return {
        ...state,
        departmentFilterList: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.TAGGING_FILTER_LIST:
      return {
        ...state,
        taggingFilterList: action.payload,
      };
    case SENIOR_MANAGEMENT_ACTIONS.STATUS_FILTER_LIST:
      return {
        ...state,
        statusFilterList: action.payload,
      };
    default:
      return state;
  }
};

export { initialSeniormanagementState, seniorManagementReducer };
