import { DAYS_COUNT, DURATION_TEXT } from "../../utils/constants/keywords";
import { format, parse } from "date-fns";

export const formatDate = (date) => {
  if (!date) return "Invalid date";
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return "Invalid date";

  const now = new Date();
  const differenceInMilliseconds = now - parsedDate;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  if (differenceInDays === 0) return DAYS_COUNT.TODAY;
  if (differenceInDays === 1) return DAYS_COUNT.ONE_DAY_AGO;
  return `${differenceInDays} ${DAYS_COUNT.DAYS_AGO}`;
};

export const Dateformat = (date) => {
  if (!date) return "Invalid date";

  try {
    const parsedDate = parse(date, "dd/MM/yyyy", new Date());

    if (isNaN(parsedDate.getTime())) {
      return "Invalid date";
    }

    return format(parsedDate, "dd-MM-yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid date";
  }
};

export const getDurationText = (raisedDate) => {
  const now = new Date();
  const raised = new Date(raisedDate);
  const diffInMs = now - raised;
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays <= 1) return DURATION_TEXT.LESS_THAN_ONE_DAY;
  if (diffInDays <= 3) return DURATION_TEXT.ONE_TO_THREE_DAYS;
  if (diffInDays <= 7) return DURATION_TEXT.FOUR_TO_SEVEN_DAYS;
  return DURATION_TEXT.MORE_THAN_SEVEN_DAYS;
};
