import * as React from 'react';
import { Link } from 'react-router-dom';
import { ACTION_BUTTONS, EMPLOYEE_STATUS, FIELD_LABELS } from '../../utils/constants/keywords';
import Icon from '../ReusableComponents/Icon';

const EmployeeCard = ({ employee }) => (
  <article className="flex flex-col  grow justify-center px-5 py-3 w-full bg-white rounded max-md:px-5  ">
    <div className="flex flex-col flex-wrap ">
      <div className="flex gap-3 box-border">
        <div className="h-16 w-16 rounded-full bg-imgBg shadow-sm border flex justify-center items-center">
          <Icon empName={employee?.name} empPhoto={employee?.profilePhoto?.url} />
        </div>
        <div className="flex flex-col ">
          <h2 className="text-lg font-semibold tracking-tight leading-8 text-black text-nowrap truncate w-40 sm:w-full md:w-44 lg:w-40  xl:w-36">
            {employee.name}
          </h2>
          <div className="flex flex-col text-xs font-medium leading-5 text-gray-500">
            <div>{employee.contactNumber}</div>
            <div className="truncate w-40 sm:w-full md:w-44 lg:w-40  xl:w-36">{employee.email}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 text-xs space-y-1">
        <div className="flex justify-between">
          <div className="text-gray-500 font-medium text-xs">{FIELD_LABELS.EMPLOYEE_ID}</div>
          <div className="text-black font-medium text-xs">{employee.employeeId}</div>
        </div>
        <div className="flex justify-between  whitespace-nowrap">
          <div className="text-gray-500 font-medium text-xs">{FIELD_LABELS.CLIENT}</div>
          <div className="text-black font-medium text-xs">
            {employee.currentEmployment && employee.currentEmployment?.[0]?.client}
          </div>
        </div>
        <div className="flex  justify-between font-medium whitespace-nowrap">
          <div className="text-gray-500 font-medium text-xs leading-[150%]">
            {FIELD_LABELS.STATUS}
          </div>
          <div className="justify-center text-black font-medium text-xs leading-[167%]">
            {(employee.isActive && employee.isActive === true && EMPLOYEE_STATUS.ACTIVE) ||
              EMPLOYEE_STATUS.INACTIVE}
          </div>
        </div>
        <div className="flex  justify-between  leading-[150%]">
          <div className=" text-gray-500 font-medium text-xs">{FIELD_LABELS.TAGGING}</div>
          <div className="flex flex-col justify-center text-black font-medium text-xs rounded-3xl">
            <div className="justify-center">
              <span className=" text-black font-medium text-xs">
                {employee.currentEmployment && employee.currentEmployment?.[0]?.tagging}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Link
        to={`/employee/${employee.employeeId}`}
        className="justify-center text-center p-2.5 mt-6 text-xs font-medium leading-5 text-indigo-500 rounded border border-indigo-500 hover:bg-indigo-500 hover:text-white border-solid max-md:px-5">
        {ACTION_BUTTONS.VIEW}
      </Link>
    </div>
  </article>
);

export default EmployeeCard;
