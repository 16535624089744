import React from 'react';
import { ACTION_BUTTONS, BREADCRUMB_STATUS } from '../../utils/constants/keywords';
import Button from '../ReusableComponents/Button';

const FormActionButton = ({
  steps,
  handleBreadcrumbBack,
  handleBreadcrumbNext,
  setIsFormSubmit=()=>{},
}) => (
  <div className="absolute w-full left-0 bottom-7 px-5 lg:px-20   mt-6 flex items-center justify-end gap-x-5 lg:gap-x-20">
    {steps[0].status !== BREADCRUMB_STATUS.CURRENT && (
      <Button
        children={ACTION_BUTTONS.BACK}
        onClick={handleBreadcrumbBack}
        type="button"
        variant={'cancel'}
      />
    )}
    {(steps[steps.length - 1].status !== BREADCRUMB_STATUS.CURRENT && (
      <Button
        children={ACTION_BUTTONS.NEXT}
        onClick={handleBreadcrumbNext}
        type="button"
        variant={'save'}
      />
    )) || (
      <Button
        children={ACTION_BUTTONS.SUBMIT}
        onClick={() => setIsFormSubmit(true)}
        type="submit"
        variant={'save'}
      />
    )}
  </div>
);
export default React.memo(FormActionButton);
