import React, { useContext, useEffect, useState } from "react";
import { getHelper } from "../utils/axios/apiServices";
import { USER } from "../lib/api";
import Container from "../Components/ReusableComponents/Container";
import EmployeeTableListing from "../Components/SeniorManagement/Dashboard/EmployeeTableListing";
import Stats from "../Components/SeniorManagement/Dashboard/Stats";
import { GlobalContext } from "../utils/context/Global/GlobalContext";

const SeniorManagement = () => {
  const [taggingCounts, setTaggingCounts] = useState({});
  const { employees, filteredEmployees, setEmployees, enums } = useContext(GlobalContext);

  useEffect(() => {
    const fetchData = async () => {
      getHelper(USER.GET_ALL_USERS, setEmployees);
      const resp = await getHelper(USER.GET_ALL_USERS, () => {});
      setTaggingCounts(resp?.taggingCounts);
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Stats taggingCounts={taggingCounts} employeesCount={employees.length} />
      <EmployeeTableListing employees={filteredEmployees} enums={enums} />
    </Container>
  );
};

export default SeniorManagement;
