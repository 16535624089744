import React, { useEffect, useState } from "react";
import { TABLE_FIELD_TYPE } from "../../../utils/constants/keywords";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../ReusableComponents/CheckBox";

const CellsData = ({
  primaryClassName,
  secondaryClassName,
  field,
  primaryChildren,
  secondaryChildren,
  navigate,
  personData,
}) => {
  if (field.type === TABLE_FIELD_TYPE.LINK) {
    let linkType = field.typeFormat(
      primaryChildren,
      field.primaryTextType1,
      field.primaryTextType2
    );
    const functionTypeSelection = (type) => {
      if (type === TABLE_FIELD_TYPE.FUNCTION) {
        let obj = {};
        field.functionChildrens.map((item) => {
          obj[item] = personData?.[item];
        });
        field.function(obj);
      } else {
        navigate(field.clickPath[linkType].path, {
          state: {
            name: personData.userName,
            email: personData.userEmail,
            dateOfBirth: personData.DOB,
          },
        });
      }
    };

    return (
      <span
        className={primaryClassName}
        onClick={() =>
          functionTypeSelection(field.clickPath[linkType].functionType)
        }
      >
        {linkType}
      </span>
    );
  } else if (field.type === TABLE_FIELD_TYPE.STATUS) {
    let statusData = field.typeFormat(
      primaryChildren,
      field.primaryTextType1,
      field.primaryTextType2
    );
    return (
      <span className={field.statusClassName[statusData]}>{statusData}</span>
    );
  } else {
    return (
      <div className="flex flex-col">
        <span className={primaryClassName}>
          {field.typeFormat(primaryChildren)}
        </span>
        {secondaryChildren && (
          <span className={secondaryClassName}>
            {field.typeFormat(secondaryChildren)}
          </span>
        )}
      </div>
    );
  }
};

const ConsentTable = ({ tableInput, dataInput }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterSelection, setFilterSelection] = useState([]);
  const handleSelectionFilter = (selectionData) => {
    setFilterSelection(selectionData);
  };
  useEffect(() => {
    setData(dataInput);
  }, [dataInput]);
  return (
    <section className=" mt-4  w-full max-md:max-w-full px-5 md:px-20">
      <div className="overflow-x-auto overflow-y-scroll bg-white rounded-lg shadow-sm">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50  text-gray-500 uppercase text-xs font-semibold">
            <tr>
              {tableInput?.map((field) => (
                <th key={field?.id} className="p-4 text-left group">
                  {field?.title}{" "}
                  {field?.filter && (
                    <>
                      <span className="relative inline-flex ml-1 align-bottom">
                        {field?.filter}
                      </span>
                      <CheckBox
                        filterList={field?.filterType}
                        handleSelectionFilter={handleSelectionFilter}
                        selectedFilters={filterSelection}
                      />
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-500">
            {data?.map((person, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-stone-50"}
              >
                {tableInput?.map((field) => (
                  <td key={field?.id} className="p-4">
                    <CellsData
                      primaryClassName={field.primaryClassName}
                      secondaryClassName={field.secondaryClassName}
                      field={field}
                      personData={person}
                      primaryChildren={person[field.primaryValue]}
                      secondaryChildren={
                        person[field.secondaryValue] &&
                        field.typeFormat(person[field.secondaryValue])
                      }
                      navigate={navigate}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default React.memo(ConsentTable);
