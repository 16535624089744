import React from "react";
import PropTypes from "prop-types";

const Dropdown = ({ options, onSelect }) => {
  return (
    <div className="absolute top-12 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
      {options.map((option) => (
        <div
          key={option.name}
          className="block border-b text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
          onClick={() => onSelect(option.name)}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dropdown;
